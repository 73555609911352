import { Component, RendererFactory2 } from '@angular/core';
import { FeaturesService } from './shared/services/features.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'seg-skills-capture';
  
  public loadLibrary: boolean;
  renderer: any;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);

    if(FeaturesService.isLiteUrl()){
      this.loadScript("assets/scripts/iframeResizer.min.js").then(() => {
        console.log('iframeResizer script loaded successfully');
      });
    } 
  }

  private loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
      this.renderer.appendChild(document.body, script); // Append to the end of the body
    });
  }

  
}
