<div class="l-layout soft-load">


  <header *ngIf="!isLiteUrl" class="l-header" >
    <div class="l-header__logo-ph logo" >
      <ng-container [ngSwitch]="currentTheme">
        <img *ngSwitchCase="1" class="l-header__logo" src="assets/logos/tp-logo_lockup-reverse.png"/>
        <img *ngSwitchCase="2" class="l-header__logo" src="assets/logos/pe-logo_reverse.png"/>
        <img *ngSwitchCase="3" class="l-header__logo" src="assets/logos/fleet-logo_reverse.png"/>
      </ng-container>
    </div>
    <div class="l-header__menu" *ngIf="!menuService.isOpen">
      <div class="header-menu hm" *ngIf="!menuService.isOpen">

        <div *ngIf="(Features.CONSULTANT_VIEW | FEATURES)" class="hm__item  user-info user-info--consultant  cursor-default" >
          <div class="user-info__badge  user-badge--48">
            <app-profile-badge [role]="userType.Consultant" [company]="currentTheme" [id]="this.authService.userInfo.userEmail"></app-profile-badge>
          </div>
          <div class="user-info__name">{{this.authService.userInfo.userName}}</div>
        </div>
        
        <div *ngIf="(!(Features.CONSULTANT_VIEW | FEATURES) && currentEducator)" class="hm__item  user-info user-info--educator  cursor-default" >
          <div class="user-info__badge  user-badge--48">
            <app-profile-badge [role]="userType.Educator" [company]="currentTheme" [id]="currentEducator.id"></app-profile-badge>
          </div>
          <div class="user-info__name">{{currentEducator.firstName}}</div>
        </div>

        <div class="hm__item" *ngIf="!(Features.CONSULTANT_VIEW | FEATURES)" (click)="logout()">Logout</div>
      </div>
    </div>
    <div class="l-header__close">
      <i app-svg-icon *ngIf="menuState == false" name="oneos-hamburger3" classList="icon  icon-24" class="l-header__icon  l-header__icon--hamburger" (click)="openMenu()"></i>
      <i app-svg-icon *ngIf="menuState == true" name="oneos-cross" classList="icon  icon-20" class="l-header__icon  l-header__icon--close" (click)="closeMenu()"></i>
    </div>
  </header>

  <header *ngIf="(Features.CONSULTANT_VIEW | FEATURES) && getShowUtilityBar()" class="l-utility-bar">
    <div class="utility-bar-wrapper">
      <app-utility-bar></app-utility-bar>
    </div>
  </header>
  
  <app-header-dropdown>
     <!-- The opening/closing of this is managed by the 'menuService' listener. -->
    <div class="header-menu  header-menu--expanded">
      <div *ngIf="(Features.CONSULTANT_VIEW | FEATURES)" class="hm__item  user-info user-info--consultant  cursor-default" >
        <div class="user-info__badge  user-badge--48">
          <app-profile-badge [role]="userType.Consultant" [company]="currentTheme" [id]="this.authService.userInfo.userName"></app-profile-badge>
        </div>
          <div class="user-info__name">{{this.authService.userInfo.userName}}</div>
      </div>
      
      <div *ngIf="(!(Features.CONSULTANT_VIEW | FEATURES) && currentEducator)" class="hm__item  user-info user-info--educator  cursor-default" >
        <div class="user-info__badge  user-badge--48">
          <app-profile-badge [role]="userType.Educator" [company]="currentTheme" [id]="currentEducator.id"></app-profile-badge>
        </div>
        <div class="user-info__name">{{currentEducator.firstName}}</div>
      </div>

      <div *ngIf="debugMode" class="hm__item" (click)="todo_remove_clearForm()">Clear form data (test)</div>
      <div *ngIf="!(Features.CONSULTANT_VIEW | FEATURES)" class="hm__item  logout" (click)="logout()">Logout</div>
    </div>
  </app-header-dropdown>
  
  <app-spinner></app-spinner>
  <app-saving-indicator appPosUnderHeader></app-saving-indicator>
  
  <main class="l-main container">
    <router-outlet></router-outlet>
  </main>

  <footer *ngIf="!isLiteUrl" class="l-footer">
    <div class="l-footer__container l-footer-container">
      <ng-container [ngSwitch]="currentTheme">
        <ng-container *ngSwitchCase="1">
          <div class="l-footer-container__company">
            <div class="l-footer__logo-ph">
              <img class="l-footer__logo" src="assets/logos/tp-logo_lockup-reverse.png"/>
            </div>
            <div class="l-footer__legal-ph">&copy; Teaching Personnel Ltd. Registered in England &amp; Wales: 3225158</div>
          </div>
          <div class="l-footer__menu-ph">
            <nav class="l-footer__menu">
              <a href="https://assets.teachingpersonnel.com/pdf/privacynoticeclients.pdf" target="_blank">Client Privacy Notice</a>
              <a href="https://assets.teachingpersonnel.com/pdf/SchoolTermsBusinessTemporary.pdf" target="_blank">Policies, Terms and Conditions</a>
              <a href="https://www.teachingpersonnel.com/privacy-policy" target="_blank">Privacy Policy</a>
              <a href="https://www.teachingpersonnel.com/cookies-policy" target="_blank">Cookies Policy</a>
            </nav>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="2">
          <div class="l-footer-container__company">
            <div class="l-footer__logo-ph">
              <img class="l-footer__logo" src="assets/logos/pe-logo_reverse.png"/>
            </div>
            <div class="l-footer__legal-ph">&copy; Protocol Education. Registered in England &amp; Wales: 02926583</div>
          </div>
          <div class="l-footer__menu-ph">
            <nav class="l-footer__menu">
              <a href="https://www.protocol-education.com/privacy-policy" target="_blank">Privacy Policy</a>
              <a href="https://www.protocol-education.com/cookies-policy" target="_blank">Cookies Policy</a>
              <a href="https://assets.protocol-education.com/pdf/PE_Child_and_Vulnerable_Adults_Protection_Policy.pdf" target="_blank">Safeguarding Policy</a>
              <a href="https://assets.protocol-education.com/pdf/PE_Client_Privacy_Notice_Nov_2020.pdf" target="_blank">Client Privacy Notice</a>
            </nav>
          </div>
        </ng-container>
        
      </ng-container>
    </div>
  </footer>
</div>
