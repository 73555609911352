import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SkillsService } from '../../services/skills.service';
import { FormCategories } from 'src/app/shared/models/api/form-categories-model';
import { NAV_URLS } from 'src/app/shared/constants/url-constants';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { environment } from '@env/environment';
import { CategoryOption } from '@app/shared/models/api/category-options-model';
import { Category } from '@app/shared/models/api/category-model';
import { Skill } from '@app/shared/models/api/skill-model';
import { BehaviorSubject, Observable, Subscription, debounceTime, forkJoin, fromEvent } from 'rxjs';
import { ModalService } from '@app/shared/services/modal.service';
import { EducatorService } from '../../services/educator.service';
import { EducatorDetails } from '@app/shared/models/api/educator-model';
import { FeaturesService } from '@app/shared/services/features.service';
import { FeaturesEnum } from '@app/shared/models/enums/features';
import { EditTrackerService } from '@app/shared/services/edit-tracker.service';
import { ModalType } from '@app/shared/models/enums/modal-type';
import { EmailService } from '../../services/email.service';
import { UtilityBarService } from '@app/shared/services/utility-bar.service';
import { UserTypeEnum } from '@app/shared/models/enums/user-type';
import { ThemeService } from '@app/shared/services/theme.service';
import { Theme } from '@app/shared/models/enums/theme';
import { EmailSentResponse } from '@app/shared/models/api/email-sent-model';

@Component({
  selector: 'app-review.page',
  templateUrl: './review.page.component.html',
  styleUrls: ['./review.page.component.scss'],
})
export class ReviewPageComponent implements AfterViewInit, OnDestroy {

  public formData: BehaviorSubject<FormCategories> = new BehaviorSubject(null);
  public formRoute: string;

  @ViewChild('rcEditModal', { static: true }) rcEditModal: TemplateRef<any>;

  @ViewChild('intro') introBlock: ElementRef;

  @ViewChildren('sectionLoading') sectionLoading: QueryList<ElementRef>;
  @ViewChildren('sectionEmpty') sectionEmpty: QueryList<ElementRef>;
  @ViewChildren('sectionPopulated') sectionPopulated: QueryList<ElementRef>;


  public modalCategory: any;
  public modalSkillId: any;
  public currentEducator: EducatorDetails;

  public isEducator: boolean = !FeaturesService.isConsultantUrl();

  public Features = FeaturesEnum;
  public userType = UserTypeEnum;
  public currentTheme: Theme;

  get isLiteUrl(): boolean {
    return FeaturesService.isLiteUrl();
  }

  public firstSection: HTMLElement;
  public resizeSubscription: Subscription;

  public emailSendStatus: BehaviorSubject<EmailSentResponse> = new BehaviorSubject(null);
  public justSentEmail: boolean = false;

  @ViewChild('utilityBarForm', { static: true }) utilityBarForm: TemplateRef<any>;

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private skillsService: SkillsService,
    public spinnerService: SpinnerService,
    public modalService: ModalService,
    private educatorService: EducatorService,
    private emailService: EmailService,
    public editTracker: EditTrackerService,
    private renderer: Renderer2,
    private utilityBarService: UtilityBarService
  ) { }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.spinnerService.showSpinner();
    this.educatorService.educatorDetails$.subscribe({
      next: (nextValue: EducatorDetails) => {
        if (nextValue) {
          this.currentEducator = nextValue;
          this.loadFormData();
          this.checkCanEmailEducator();
        }
      }
    });

    this.currentEducator = this.educatorService.educatorDetails$.value;
    if (this.currentEducator) {
      this.loadFormData();
    }

    // This handles the UI format for the intro .
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(50))
      .subscribe(() => this.onResize());
  }

  private checkCanEmailEducator() {

    if (FeaturesService.CONSULTANT_VIEW()) {

      this.emailService.loadCanEmailEducator(this.educatorService.educatorDetails$.value.id).subscribe({
        next: (canSendEmailResp: EmailSentResponse) => {
          this.emailSendStatus.next(canSendEmailResp);
        }
      });

    }

  }

  ngOnDestroy(): void {
    this.utilityBarService.clearTemplates();
  }

  onResize() {

    // Intro (Educator view only)
    if (this.firstSection) {
      this.IntroResize();
    }

  }

  ngAfterViewInit() {
    this.utilityBarService.addTemplate(this.utilityBarForm);
  }

  public IntroResize() {
    if (this.introBlock) {
      this.firstSection = document.querySelector('.ss--0') as HTMLElement;
      let left = this.firstSection.offsetLeft;
      this.renderer.setStyle(this.introBlock.nativeElement, 'margin-left', left + 'px');
      this.renderer.setStyle(this.introBlock.nativeElement, 'margin-right', left + 'px');
    }
  }


  private loadFormData() {
    if (this.skillsService.formCategories$.value) {
      this.updateFormData(this.skillsService.formCategories$.value);
      this.spinnerService.hideSpinner();
    }
    else {
      this.skillsService.formCategories$.subscribe({
        next: (nextValue: FormCategories) => {
          if (nextValue) {
            console.log('nextValue', nextValue);

            this.updateFormData(nextValue);
            this.spinnerService.hideSpinner();
          }
        },
      });
    }
  }

  private updateFormData(latestFormData: FormCategories) {
    this.formData.next(latestFormData);

    this.formRoute = latestFormData.formRoute;    

    // Iterate through each category in the response
    const categoryObservables: Observable<Category>[] = latestFormData.categories.map(category =>
      this.skillsService.getformCategory(latestFormData.formRoute, category.categoryRoute, this.currentEducator.id)
    );


    // ForkJoin waits for all observables to complete
    forkJoin(categoryObservables).subscribe(categories => {
      // Update formCategories with the complete data
      this.formData.next({ ...latestFormData, categories });
    console.log('this.formData', this.formData);

      this.IntroResize();
    });

  }

  public filterSelectedSkills(skills: Skill[]) {
    return skills.filter(x => x.isSelected);
  }

  public categoryHasUserData(category: Category) {
    return category.skills.findIndex(x => x.isSelected == true) > -1;
  }

  public categoryHasLockedSkill(category: Category) {
    return category.skills.some(x => x.isLocked == true);
  }

  public navigateToForm(route: string,) {
    if (this.editTracker.editMade == true) {
      this.editTracker.clearTracker();
    }

    let form = this.formData.value;
    form.categories.forEach(cat => {
      let len = cat.skills.filter(x => x.isSelected).length;
    });

    this.router.navigate([`./${NAV_URLS.REVIEW_PAGE}/${NAV_URLS.FORM_PAGE}/${this.formRoute}/${route}`]);
  }

  public consultantEditSkill(contextCategory: Category, contextSkill: Skill) {

    this.modalCategory = contextCategory.categoryRoute;
    this.modalSkillId = contextSkill.id;
    this.modalService.openWindow(this.rcEditModal, null, true, ModalType.Default, '600px');
  }



  public clearForm() {
    this.spinnerService.showSpinner();

    this.skillsService.deleteUserForm(this.currentEducator.id).subscribe({
      next: (x) => {
        console.log('DELETED FORM DATA');
      },
      error(err) {

      },
      complete: () => {
        this.spinnerService.hideSpinner();
      }
    });

  }

  public getSkillLevelName(skillOptionId: number, options: CategoryOption[]) {
    let option = options.find(x => x.id == skillOptionId)

    return option.name;
  }

  public getSkillLevelOrder(skillOptionId: number, options: CategoryOption[]) {
    let option = options.find(x => x.id == skillOptionId)

    return option.displayOrder;
  }


  public reload() {
    console.log('this.onDataChanged.emit(true);');
    this.updateFormData(this.skillsService.formCategories$.value);
  }


  public sendEmailToEducator() {
    console.log('Send email to Educator.');


    this.emailService.sendEducatorInviteEmail(this.educatorService.educatorDetails$.value.id).subscribe({
      next: (sentEmailResp: boolean) => {
        this.justSentEmail = sentEmailResp;
        this.emailSendStatus.next({ canSendEmail: !sentEmailResp, lastSent: new Date() });
      }
    });

  }

}
