export class AuthenticatedUser {
  id: number;
  userEmail: string;
  authenticated: boolean;
  token: any;
  msalToken: any;
  refreshToken: any;
  tokenValid: boolean = false;
  userName: string;
  identityToken: string;
  tokenInvalid: boolean;

  constructor() {
    this.userEmail = '';
    this.authenticated = false;
    this.token = null;
    this.msalToken = null;
    this.userName = '';
    this.identityToken = '';
    this.id = 0;
  }
}
