import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TokenResponse } from "@app/shared/models/api/token-response";
import { BehaviorSubject, Observable, throwError, switchMap, catchError, of, tap, filter, take } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { SegIdentityService } from "../seg-identity/seg-identity.service";
import { FeaturesService } from "@app/shared/services/features.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private segIdentityService: SegIdentityService
  ) {
    
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let isConsultantUrl = FeaturesService.isConsultantUrl();
    if(!isConsultantUrl){
      return this.doEducatorInterceptor(request, next);
    }
    else{
      return this.doConsultantInterceptor(request, next);
    }
  }

  private doEducatorInterceptor(request: HttpRequest<unknown>, next: HttpHandler) : Observable<HttpEvent<any>>{
    if(request.url.includes('User/SegIdentity')){
      return this.handleNext(request, next);
    }

    if (!this.segIdentityService.userInfo.authenticated) {
      this.router.navigate(['/login']);
      return throwError(() => 'User not authenticated');
    }

    if (
      this.segIdentityService.userInfo.token !== null &&
      !request.url.includes('RefreshAccessToken')
    ) {
      request = this.addToken(request, this.segIdentityService.userInfo.token);
      return this.handleNext(request, next);
    } else if (request.url.includes('Access/GetAccessToken')) {

      let token = this.segIdentityService.userInfo.token;

      request = this.addToken(request, token);
      return this.handleNext(request, next);
    } else if (request.url.includes('RefreshAccessToken')) {
      request = this.addToken(request, this.segIdentityService.userInfo.refreshToken);
      return this.handleNext(request, next);
    } else {
      return this.handleInitial(request, next);
    }
  }

  private doConsultantInterceptor(request: HttpRequest<unknown>, next: HttpHandler) : Observable<HttpEvent<any>>{
    if (!this.segIdentityService.userInfo.authenticated) {
      this.router.navigate(['/login']);
      return throwError(() => 'User not authenticated');
    }

    if (
      this.segIdentityService.userInfo.token !== null &&
      !request.url.includes('RefreshAccessToken')
    ) {
      request = this.addToken(request, this.segIdentityService.userInfo.token);
      return this.handleNext(request, next);
    } else if (request.url.includes('Access/GetAccessToken')) {

      let token = this.segIdentityService.userInfo.msalToken;

      request = this.addToken(request, token);
      return this.handleNext(request, next);
    } else if (request.url.includes('RefreshAccessToken')) {
      request = this.addToken(request, this.segIdentityService.userInfo.refreshToken);
      return this.handleNext(request, next);
    } else {
      return this.handleInitial(request, next);
    }
  }

  private handleInitial(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = FeaturesService.isConsultantUrl() ? this.segIdentityService.userInfo.msalToken : this.segIdentityService.userInfo.token;

    return this.authService
      .getTokensFromAuthApi(token)
      .pipe(
        switchMap((response: TokenResponse) => {
          this.segIdentityService.setInfoFromToken(response);
          request = this.addToken(request, this.segIdentityService.userInfo.token);
          return this.handleNext(request, next);
        }),
        catchError((error: any) => {
          // Handle specific error conditions
          if (error.status === 401 || error.status === 403) {
            console.error('Authentication error:', error);
            this.router.navigate(['/login']);
            return of(null);
          } else {
            // Propagate other errors
            return throwError(() => error);
          }
        })
      );
  }

  private handleNext(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any) => {

        if(error.url.includes('GetAccessToken') && error.status === 401 && this.router.url.startsWith('/login')){
          this.router.navigate(['/login-unauthorised']);
          return throwError(() => error);
        }

        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (error.url.includes('RefreshAccessToken')) {
            return this.handleInitial(request, next);
          }
          return this.handle401Error(request, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.getRefreshTokensFromSegOsCentralAuth().pipe(
        tap((response) => {}),
        switchMap((response: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.token);
          request = this.addToken(request, response.token);
          return this.handleNext(request, next);
        }),
        catchError((err) => {
          return throwError(() => err);
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      switchMap((token) => {
        return next.handle(this.addToken(request, token));
      })
    );
  }
}
