import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-svg-icon]',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {

  @Input()
  set name(iconName: string) {
    this._name = `assets/icons/icons.svg#${iconName}`;
  }

  @Input() color: string = "currentColor";
  @Input() classList: string = "";
  
  get name(): string {
    return this._name;
  }
  private _name: string = "";
  
  constructor() { }

  ngOnInit(): void {
  }

}
