import { AfterContentInit, AfterViewInit, Component, Inject, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EducatorService } from '@app/_features/skills-capture/services/educator.service';
import { NAV_URLS } from '@app/shared/constants/url-constants';
import { EducatorDetails } from '@app/shared/models/api/educator-model';
import { FeaturesEnum } from '@app/shared/models/enums/features';
import { Theme } from '@app/shared/models/enums/theme';
import { FeaturesService } from '@app/shared/services/features.service';
import { MenuService } from '@app/shared/services/menu.service';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import { UserTypeEnum } from '@app/shared/models/enums/user-type';
import { SkillsService } from '@app/_features/skills-capture/services/skills.service';
import { ModalService } from '@app/shared/services/modal.service';
import { ModalType } from '@app/shared/models/enums/modal-type';
import { SegIdentityService } from '@app/_auth/seg-identity/seg-identity.service';
import { DOCUMENT } from '@angular/common';
import { UtilityBarService } from '@app/shared/services/utility-bar.service';

@Component({
  selector: 'app-homepage-layout',
  templateUrl: './homepage-layout.component.html',
  styleUrl: './homepage-layout.component.scss'
})
export class HomepageLayoutComponent {

  public toRemove_diag: boolean;
  public spinnerState: string = 'closed'; // open / closed / closing
  public init: boolean = false;
  public activeTheme$: Observable<Theme>;
  public currentTheme: Theme;
  public currentEducator: EducatorDetails;

  public Features = FeaturesEnum;

  get isLiteUrl(): boolean {
    return FeaturesService.isLiteUrl();
  }
  get getLogo(): string {
    return this.themeService.currentTheme.toString();
  }

  public menuState: boolean;
  public userType = UserTypeEnum;

  public debugMode: boolean;

  @ViewChild('rcErrorModal', { static: true }) rcErrorModal: TemplateRef<any>; // TODO: remove after testing
  public modalCategory: any;

  constructor(
    private renderer: Renderer2,
    public spinnerService: SpinnerService,
    private router: Router,
    private themeService: ThemeService,
    private educatorService: EducatorService,
    public menuService: MenuService,
    private skillsService: SkillsService, // todo - remove after testing ???
    public modalService: ModalService, // todo - remove after testing ???
    private utilityBarService: UtilityBarService,
    public authService: SegIdentityService,
    @Inject(DOCUMENT) private document: any
  ) {
    if(FeaturesService.isLiteUrl()) {
      this.renderer.addClass(this.document.documentElement , 'lite');
      this.renderer.addClass(this.document.body, 'lite');
    }
    this.debugMode = environment.debugMode;
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.getEducator();

    this.menuService.openState.subscribe(value => {
      this.menuState = value;
    })

    this.getShowUtilityBar();
  }



  public getShowUtilityBar() {
    return this.utilityBarService.templates.size > 0;
  }

  private getEducator() {
    this.educatorService.educatorDetails$.subscribe({
      next: (nextValue: EducatorDetails) => {
        if (nextValue) {
          this.currentEducator = nextValue;
        }
      }
    });

    this.currentEducator = this.educatorService.educatorDetails$.value;
  }


  public logout() {
    this.router.navigate([`./${NAV_URLS.LOGOUT_PAGE}`]);
  }


  public openMenu() {
    this.menuService.open();
  }
  public closeMenu() {
    this.menuService.close();
  }



  // todo: Remove test stuff:

  public todo_remove_clearForm() { // todo: Remove after testing:
    this.spinnerService.showSpinner();

    this.skillsService.deleteUserForm(this.currentEducator.id).subscribe({
      next: (x) => {
        console.log('DELETED FORM DATA');
      },
      error(err) {

      },
      complete: () => {
        this.spinnerService.hideSpinner();
      }
    });

  }

}
