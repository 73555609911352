<div class="form" *ngIf="category">
    <h1 *ngIf="showTitle">Why are you amending this level?</h1>

    <div class="form-panel form-panel--highlighted" *ngIf="skill; else loadingSkill">
        <div class="form-panel__header">
            <label>{{ skill.name }}</label>
            <app-form-toggle (valueChanged$)="onSkillToggled($event)" [isChecked]="skill.isSelected"></app-form-toggle>
        </div>

        <div class="form-panel__subheader" *ngIf="skill.isSelected && skill.headerText">
            <label>{{ skill.headerText }}</label>
        </div>

        <div class="form-panel__options"
            *ngIf="skill.isSelected && category.categoryOptions && category.categoryOptions.length > 0">
            <app-field-switcher [type]="category.fieldType" [options]="category.categoryOptions" [skill]="skill" 
            (valueChanged$)="onOptionChange($event)"></app-field-switcher>
        </div>
    </div>

    <div class="form-panel">
        <div class="form-field" *ngIf="skill.lockInfo" 
        [appContainerQuery]="{
            className: 'form-field--stacked',
            operator: 'lt',
            breakpoint: (180+400)
          }">
            <label class="field-label">Reason</label>
            <div class="field-group" [ngClass]="{'field-group--error' : showValidationError}">
                <app-form-select-dropdown [categoryName]="'Reason Type'" [options]="dropDownReasonTypes" (onDropDownSelectionChange)="selectReason($event)" [defaultSelected]="selectedIndex"></app-form-select-dropdown>
                <label class="field-error" *ngIf="showValidationError">You must select a reason!</label>
            </div>
        </div>
        
        
        <div class="form-field" *ngIf="skill.lockInfo" 
        [appContainerQuery]="{
            className: 'form-field--stacked',
            operator: 'lt',
            breakpoint: (180+400)
          }">
            <label class="field-label">Additional comments</label>
            <div class="field-group">
                <textarea [(ngModel)]="skill.lockInfo.reasonDescription" rows="5" placeholder="Enter any additional reasons for this amendment..."></textarea>
            </div>
        </div>
    </div>
    
    
    <div class="btn-group  btn-group--right">
        <button class="btn btn--ghost" (click)="modalService.closeWindow()">Close</button>
        <button class="btn" (click)="saveChanges()">Save Changes</button>
    </div>
</div>



<ng-template #loadingSkill>
    <div class="form-panel">
        <app-loading-spinner></app-loading-spinner>
    </div>
</ng-template>