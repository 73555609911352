import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-field-lock',
  templateUrl: './field-lock.component.html',
  styleUrl: './field-lock.component.scss'
})
export class FieldLockComponent implements AfterViewInit {

  @Input('classList') classList: string;
  @ViewChild('iconWrapper') iconWrapper: ElementRef

  constructor(
    private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    this.renderer.addClass(this.iconWrapper.nativeElement, this.classList);
  }

}
