import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { DropDownOption } from '@app/shared/models/forms/dropdown-option';

@Component({
  selector: 'app-form-select-dropdown',
  templateUrl: './form-select-dropdown.component.html',
  styleUrl: './form-select-dropdown.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FormSelectDropdownComponent implements AfterViewInit { 


  @ViewChildren("ddlItem") ddlItems: QueryList<ElementRef>;
  @ViewChild("ddlInput") ddlInput: ElementRef;
  @ViewChild("ddlList") ddlList: ElementRef;
  @ViewChild('ddlTemplate', { static: true }) ddlTemplate: TemplateRef<any>;
  
  @HostListener('focusout', ['$event.target']) onFocusout(e: any) {
    console.log('blur', e);
    this.selectionActive = false;
    this.highlightedItem = 0;

    setTimeout(() => {
      this.renderer.setStyle(this.ddlList.nativeElement, 'display', 'none');
    }, 200);
  }

  @Output() onDropDownSelectionChange: EventEmitter<any> = new EventEmitter();

  private _selectedItem: DropDownOption<any>;
  public get selectedItem(): DropDownOption<any> {
    return this._selectedItem;
  }
  public set selectedItem(value: DropDownOption<any>) {
    this._selectedItem = value;  
  }

  public get selectedItemText(): string {
    if(this._selectedItem && this._selectedItem.value){
      return this._selectedItem.value;
    }

    return null;
  }

  private selectionActive: boolean = false;
  private highlightedItem: number = 0;
  public dropDownPlaceHolderText: string;

  @Input('options') options: DropDownOption<any>[] = [];
  @Input('categoryName') categoryName: string;

  @Input('defaultSelected') defaultSelected: number = -1;

  constructor(private renderer: Renderer2) {

  }
  ngOnInit(): void {
    this.dropDownPlaceHolderText = `Select your ${this.categoryName}`;
  }
  
  ngAfterViewInit () {
    if(this.defaultSelected > -1){
      this.choose(this.options[this.defaultSelected]);
    }
  }

  public expand() {
    this.renderer.setStyle(this.ddlList.nativeElement, 'display', 'block');
    this.ddlInput.nativeElement.focus();
  }

  public choose(item: DropDownOption<any>) {
    
    this.selectedItem = item;
    this.onDropDownSelectionChange.next(item.data);

    this.renderer.setStyle(this.ddlList.nativeElement, 'display', 'none');
  }

  public reset() {
    this.selectionActive = false;
    this.highlightedItem = 0;
    this.ddlInput.nativeElement.focus();
  }

  public onKeyDown(event: KeyboardEvent) {

    if (event.key === "ArrowUp" && this.selectionActive == false) {
      console.log("Up arrow key pressed");
      this.selectionActive = true;
    }

    if (event.key === "ArrowUp" && this.highlightedItem >= 1) {
      console.log("Up arrow key pressed");

      this.highlightedItem--;
      this.selectionActive = true;
      this.scroll(this.highlightedItem, 0);
    }

    if (event.key === "ArrowDown" && this.highlightedItem < this.options.length -1) {
      console.log("Down arrow key pressed", this.highlightedItem);

      this.selectionActive == false ? this.highlightedItem = 0 : this.highlightedItem++;
      this.selectionActive = true;
      this.scroll(this.highlightedItem, 1);
    }

    console.log('this.highlightedItem',this.highlightedItem);

    if (event.key === "Enter" && this.selectionActive == true) {
      this.choose(this.options[this.highlightedItem]);
    }
  }


  public scroll(item: number, direction: number) {
    if (!this.canScroll) {
      return;
    }

    // Get the height of an item.
    const itemHeight = this.ddlItems.first.nativeElement.offsetHeight;

    // Get the height of the div.
    const listHeight = this.ddlList.nativeElement.offsetHeight;

    const div = this.ddlList.nativeElement;
    console.log('div.scrollTop', div.scrollTop);

    console.log('((item-1) * itemHeight)',((item-1) * itemHeight));
    
    if (direction == 0) {
      if (((item-1) * itemHeight) < div.scrollTop){
        div.scrollTop -= itemHeight;
      }
    }
    else {
      if (((item+1) * itemHeight) > listHeight){
        div.scrollTop += itemHeight;
      }
    }
    
  }

  public canScroll(): boolean {

    // Get the height of an item.
    const itemHeight = this.ddlItems.first.nativeElement.offsetHeight;

    // Get the height of the div.
    const listHeight = this.ddlList.nativeElement.offsetHeight;

    if (itemHeight * this.options.length > listHeight) {
      return true;
    }
    else {
      return false;
    }
  }

}
