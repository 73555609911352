import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityBarService {

  public templates: Set<TemplateRef<any>> = new Set<TemplateRef<any>>();
  
  constructor() { }

  public addTemplate(template: TemplateRef<any>): void {
    this.templates.add(template);

    console.log(this.templates);
  }

  public clearTemplates(): void {
    this.templates.clear();
  }

  public removeTemplate(template: TemplateRef<any>): void {
    this.templates.delete(template);
  }

}
