import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "@env/environment";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

function loggerCallback(logLevel: LogLevel, message: string) {
    // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    let currentUrl = window.location.origin + "/";
    return new PublicClientApplication({
        auth: {
            clientId: environment.ApplicationID,
            authority: 'https://login.microsoftonline.com/' + environment.ADDirectoryID,
            redirectUri: currentUrl,
            postLogoutRedirectUri: currentUrl + "logout"
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map([
        environment.msalConfig.apiResourceAndScope
    ]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: environment.msalConfig.apiScopes
        },
        loginFailedRoute: '/login'
    };
}