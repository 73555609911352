import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '@env/environment';
import { ApiBaseService } from '@app/shared/services/api-base.service';
import { SessionUserHashManagerService } from './session-user-hash-manager.service';
import { SegIdentityService } from '../seg-identity/seg-identity.service';
import { ModalService } from '@app/shared/services/modal.service';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiBaseService {

  public readonly apiUrl: string = environment.segIdentityAuthApi;

  constructor(
    private http: HttpClient, 
    protected hashManagerService: SessionUserHashManagerService,
    protected override authService: AuthenticationService,
    protected override segIdentityService: SegIdentityService,
    protected override modalService: ModalService
  ) {
    super(http, authService, hashManagerService, segIdentityService, modalService)
    this.apiUrl += environment.segIdentityAuthApi.endsWith("/") ? "" : "/";
  }

  fetchSegIdentityUserTokenFromAuthApi(token: string, companyId: number) {
    var body = {"Token": token, "ApplicationId": companyId};

    return this.http.post(this.apiUrl + 'api/User/SegIdentity' , body, this.prepapeSsoAnonymousOptions(body, token)).pipe(
      map((data: any) => {
        
        if (data.status === 299) {
          throw {
            message: data.body,
            status: data.status,
            isValidationError: true
          };
        } else if (data.name && data.name == "HttpErrorResponse") {
          throw {
            message: data.body,
            status: data.status,
            isValidationError: false
          };
        } else {
          return data.body;
        }
      }));
  }
}
