import { Component } from '@angular/core';
import { SpinState } from '@app/shared/models/enums/spinner-state';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrl: './loading-spinner.component.scss'
})
export class LoadingSpinnerComponent {

  public readonly SpinState : typeof SpinState = SpinState;

  constructor(public spinnerService: SpinnerService) {

  }

}
