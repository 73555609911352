import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SessionUserHashManagerService {

  private currentSessionUserHash$: string = "";

  constructor(
    protected router: Router,
    private cookieService: CookieService
    ) { }
  
  public setUserHash(user: any): void {
    let hash = this.generateHash(user);
    this.currentSessionUserHash$ = hash;
    this.cookieService.set("h", hash, undefined, '/', undefined, undefined, 'Lax');
  }
  
  public checkUser(): void {
    let localStorageOrigUser = localStorage.getItem('originalUser');
    let localStorageCurUser = localStorage.getItem('currentUser');
    if (localStorageOrigUser && localStorageCurUser) {
      let originalUser = JSON.parse(localStorageOrigUser);
      let loggedInUser = JSON.parse(localStorageCurUser);
      let loggedInUserHash = this.generateHash(loggedInUser);

      let currentSessionUserHash = this.getCurrentSessionUserHash();

      let cookie = this.cookieService.get("h");
      if (currentSessionUserHash == null || currentSessionUserHash == "") {
        this.setUserHash(originalUser)
        currentSessionUserHash = this.getCurrentSessionUserHash();
      }

      if (cookie == null || cookie == "" || currentSessionUserHash == "") {
        // nobody is logged in
        return;
      } else if (cookie != currentSessionUserHash) {
        this.currentSessionUserHash$ = cookie;
        this.router.navigate(["/"]);
      } else if (cookie == currentSessionUserHash) {
        if (loggedInUserHash != cookie) {
          // someone is tampering with the cookie
          this.router.navigate(["/login"]);
        }
      }
    }
  }

  public checkUserForExternal(): void {
    let localStorageOrigUser = localStorage.getItem('originalUser');
    let localStorageCurUser = localStorage.getItem('currentUser');
    if (localStorageOrigUser && localStorageCurUser) {
      let originalUser = JSON.parse(localStorageOrigUser);
      let loggedInUser = JSON.parse(localStorageCurUser);
      let loggedInUserHash = this.generateHash(loggedInUser);

      let currentSessionUserHash = this.getCurrentSessionUserHash();

      let cookie = this.cookieService.get("h");
      if (currentSessionUserHash == null || currentSessionUserHash == "") {
        this.setUserHash(originalUser)
        currentSessionUserHash = this.getCurrentSessionUserHash();
      }

      if (cookie == null || cookie == "" || currentSessionUserHash == "") {
        // nobody is logged in
        this.router.navigate(['/login'], { queryParams: { returnUrl: window.location.pathname } });
      } else if (cookie == currentSessionUserHash) {
        if (loggedInUserHash != cookie) {
          // someone is tampering with the cookie
          this.router.navigate(['/login'], { queryParams: { returnUrl: window.location.pathname } });
        }
      } else if (cookie != currentSessionUserHash) {
        this.currentSessionUserHash$ = cookie;
      }

      //this.featureHelperService.setUserFeatures(originalUser.userFeatures);
    }
  }

  public clearSessionHash(): void {
    this.currentSessionUserHash$ = "";
    this.cookieService.delete("h");
  }

  public validateHash(hash: string): boolean {
    let localStorageCurUser = localStorage.getItem('currentUser');
    if (localStorageCurUser) {
      let loggedInUser = JSON.parse(localStorageCurUser);
      let loggedInUserHash = this.generateHash(loggedInUser);

      let cookie = this.cookieService.get("h");

      return hash == loggedInUserHash && hash == cookie;
    }
    return false;
  }
  
  public getCurrentSessionUserHash(): string {
    return this.currentSessionUserHash$;
  }

  private generateHash(objectToHash: any): string {
    let base64Hash = "";
    if (objectToHash != null  && objectToHash != undefined) {
      let hashSalt: string = "STEPS/LEAP is revolutionary";
      base64Hash = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA512(JSON.stringify(objectToHash), hashSalt));
    }
    return base64Hash;
  }

}
