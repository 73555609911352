import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { EducatorDetails } from '@app/shared/models/api/educator-model';
import { FeaturesEnum } from '@app/shared/models/enums/features';
import { Theme } from '@app/shared/models/enums/theme';
import { UserTypeEnum } from '@app/shared/models/enums/user-type';
import { ThemeService } from '@app/shared/services/theme.service';
import { fromEvent, debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-bar',
  templateUrl: './profile-bar.component.html',
  styleUrl: './profile-bar.component.scss'
})
export class ProfileBarComponent { 

  public Features = FeaturesEnum;
  public userType = UserTypeEnum;
  public currentTheme: Theme;

  @ViewChild('udImage') udImage: ElementRef<any>;
  @ViewChild('udInfo') udInfo: ElementRef<any>;

  @Input('currentEducator') currentEducator: EducatorDetails;
  public resizeSubscription: Subscription;

  @Input('badgesize') badgeSize: number = 96;

  constructor(private renderer: Renderer2,
              private themeService: ThemeService) {
    
  }

  ngOnInit() {

    this.currentTheme = this.themeService.currentTheme;

    // This handles the UI format for the intro .
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(50))
      .subscribe(() => this.onResize());
  }

  onResize() { 

    // UserInfo and badge (consultant view only)
    if(this.udImage && this.udInfo) {
      let elLeft = this.udImage.nativeElement.getBoundingClientRect().bottom;
      let elRight = this.udInfo.nativeElement.getBoundingClientRect().top;
      
      if(elRight > elLeft ) {
        this.renderer.setStyle(this.udInfo.nativeElement, 'text-align', 'center');
      }
      else {
        this.renderer.setStyle(this.udInfo.nativeElement, 'text-align', 'left');
      }
    }
  }

}
