import { environment } from '@env/environment';

export abstract class FeaturesService {
  constructor() {

  }

  public static SKILL_NAV(): boolean {
    return !this.isConsultantUrl();
  }

  public static SKILL_ITEM_EDIT(): boolean {
    return this.isConsultantUrl();
  }

  public static CONSULTANT_VIEW(): boolean {
    return this.isConsultantUrl();
  }

  public static VIEW_ALL_EDUCATORS(): boolean { 
    return this.isConsultantUrl();
  }

  public static isConsultantUrl() {
    let currentUrl = window.location.hostname;
    let isConsultantUrl = false;

    if (currentUrl.indexOf(environment.educatorDomain) > -1) {
      isConsultantUrl = false;
    } 
    else if (currentUrl.indexOf(environment.consultantDomain) > -1) {
      isConsultantUrl = true;
    }
    else{
        isConsultantUrl = !environment.localhostIsEducatorOverride;
    }

    return isConsultantUrl;
  }

  public static showHeaderAndFooter() : boolean {
    return !this.isLiteUrl();
  }

  public static isLiteUrl() : boolean {
    let currentUrl = window.location.hostname;
    let isLiteUrl = false;

    if (currentUrl.indexOf(environment.liteDomain) > -1) {
      isLiteUrl = true;
    } 
    else{
        isLiteUrl = environment.localhostIsLitePageOverride;
    }

    return isLiteUrl;
  }
}
