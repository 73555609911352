import { DOCUMENT } from '@angular/common';
import { HostListener, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public isOpen: boolean = false;
  public openState = new BehaviorSubject<boolean>(false);

  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: any
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }



  public open(): void {
    this.isOpen = true;
    this.openState.next(true);
    this.renderer.addClass(this.document.body, 'menu-open');
    this.scrollfix();
  }

  public close(): void {
    this.isOpen = false;
    this.openState.next(false);
    this.renderer.removeClass(this.document.body, 'menu-open');
    this.scrollfix();
  }

  public toggle() {
    this.isOpen = !this.isOpen;
    this.openState.next(this.isOpen);
    this.scrollfix();
  }

  public scrollfix() {
    const clientHeight = this.document.body.clientHeight ;
    const scrollHeight = this.document.body.scrollHeight;

    if((scrollHeight > clientHeight) && this.isOpen) {
      this.renderer.addClass(this.document.body, 'menu-scrollfix');
    }
    else {
      this.renderer.removeClass(this.document.body, 'menu-scrollfix');
    }
  }
}
