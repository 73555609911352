import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {

  @Input("appTooltip") cfg: TooltipConfig;
  public div: HTMLElement

  public defultOffsetX: number = 0;
  public defultOffsetY: number = -45;

  public screenHeight: number;
  public screenWidth: number;

  public offsetX: number = 0;
  public offsetY: number = 0;

  private tooltipTimeout: any; // Stores the timeout
  private tooltipVisible: boolean = false;

  private isTouch = false;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) { }


  @HostListener('touchstart', ['$event']) onTouchStart(e: TouchEvent) {
    this.isTouch = true;
    this.clearTooltipTimeout();
    const { clientX, clientY } = this.getTouchCoordinates(e);
    this.showTooltip(clientX, clientY);
    if (e.cancelable) {
      e.preventDefault();
    }
  }

  @HostListener('touchend', ['$event']) onTouchEnd(e: TouchEvent) {
    this.startTooltipTimeout();
    e.preventDefault();
  }

  @HostListener('document:touchstart', ['$event'])
  onDocumentTouchStart(e: TouchEvent) {
    if (this.isTouch && !this.el.nativeElement.contains(e.target)) {
      this.removeTooltip();
      this.clearTooltipTimeout();
    }
  }


  @HostListener('mouseenter', ['$event']) onMouseEnter(e: MouseEvent) {
    if (!this.isTouch) {
      this.showTooltip(e.clientX, e.clientY);
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    if (!this.isTouch && !document.getElementById("app-tooltip")) {
      return;
    }
    this.checkPosition(e.clientX, e.clientY);
  }


  @HostListener('mouseleave') onMouseLeave() {
    if (!this.isTouch) {
      this.removeTooltip();
    }
  }

  @HostListener('window:scroll', ['$event']) onScroll(e: MouseEvent) {
    this.removeTooltip();
  }


  private getTouchCoordinates(event: TouchEvent): { clientX: number, clientY: number } {
    const touch = event.touches[0] || event.changedTouches[0];
    return {
      clientX: touch.clientX,
      clientY: touch.clientY
    };
  }



  public showTooltip(clientX: number, clientY: number) {

    if (this.tooltipVisible) { return }

    if (this.cfg.text == '' || this.cfg.text == undefined) {
      return;
    }
    let cfgSize = this.cfg.size ? this.cfg.size : 'fit';
    this.cfg.size = cfgSize;

    let div: HTMLElement = this.renderer.createElement('div');
    this.renderer.setAttribute(div, 'id', 'app-tooltip');
    this.renderer.addClass(div, 'app-tooltip');
    this.renderer.setStyle(div, 'position', 'fixed');
    this.renderer.setStyle(div, 'z-index', '99');
    this.renderer.addClass(div, 'app-tooltip--' + cfgSize);

    // Add the tooltip to the DOM.
    let arrTxt = this.cfg.text.split('/r/n');

    arrTxt.forEach((n: string, idx: number) => {
      let txt = this.renderer.createText(n);
      this.renderer.appendChild(div, txt);

      if (idx < arrTxt.length - 1) {
        let br = this.renderer.createElement('br');
        this.renderer.appendChild(div, br);
      }
    });

    this.renderer.appendChild(document.body, div);
    this.div = div;

    // Get the size of the viewport:
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    this.checkPosition(clientX, clientY);

    this.tooltipVisible = true;
  }


  private startTooltipTimeout() {
    this.tooltipTimeout = setTimeout(() => {
      this.removeTooltip();
      this.isTouch = false;
    }, 3000);
  }

  private clearTooltipTimeout() {
    if (this.tooltipTimeout) {
      clearTimeout(this.tooltipTimeout);
      this.tooltipTimeout = null;
    }
  }



  public async changeWidth(len: number, x: number, y: number) {
    this.renderer.removeClass(this.div, 'app-tooltip--fit');
    this.renderer.addClass(this.div, 'app-tooltip--md');
    this.cfg.size = 'md';
  }

  public removeTooltip() {
    this.tooltipVisible = false;
    this.isTouch = false;

    if (!document.getElementById("app-tooltip")) {
      return;
    }
    this.renderer.removeChild(document.body, document.getElementById("app-tooltip"));

  }

  public async checkPosition(x: number, y: number) {

    // Set the default offsets:
    this.offsetX = (-(this.div.clientWidth / 2)); // Center align to cursor.
    this.offsetY = this.defultOffsetY; // Float above cursor.
    this.renderer.setStyle(this.div, 'left', x + this.offsetX + 'px');
    this.renderer.setStyle(this.div, 'right', '20px');

    let t = this.cfg.text;
    let s = this.cfg.size;

    if (t.length > 100 && (s == 'fit' || s == '')) {
      await this.changeWidth(t.length, x, y);
    }


    // First check the left & right pos as this will decide the height of the box.
    // Left 
    if (x - (this.div.clientWidth / 2) < 0) {
      this.offsetX = 20;
      this.renderer.setStyle(this.div, 'left', x + this.offsetX + 'px');
    }

    // Right 
    if (x + (this.div.clientWidth / 2) > this.screenWidth) {
      this.renderer.setStyle(this.div, 'left', this.screenWidth - this.div.clientWidth - 20 + 'px');
    }


    // Next do the vertical. OffsetY is in relation to the cursor.
    // Top

    if (y - (Math.abs(this.offsetY) + this.div.clientTop) < 0) {
      this.offsetY = -10;
    }
    else {
      this.offsetY = this.defultOffsetY;
    }
    this.renderer.setStyle(this.div, 'top', y + this.offsetY + 'px');

    // Bottom 
    if ((y + this.div.clientHeight) - Math.abs(this.offsetY) > this.screenHeight) {
      this.offsetY = -(this.div.clientHeight + 20);
    }

    // Check if target is obscured.
    if (
      (this.div.offsetTop < y) && (this.div.offsetTop + this.div.clientHeight) > y &&
      (this.div.offsetLeft < x) && (this.div.offsetLeft + this.div.clientWidth) > x) {
      this.offsetY = -this.defultOffsetY;
    }

    this.renderer.setStyle(this.div, 'top', y + this.offsetY + 'px');
  }


}

export class TooltipConfig {
  text: string;  // Freetext.
  size?: string;   // fit | sm | md | lg | xl
}