
<div ddl class="dropdown-typeahead ddl">
  
  <input #ddlInput class="ddl__input" type="text" [(ngModel)]="filterText" (input)="filterItems()" (click)="expand()" (keydown)="onKeyDown($event)" [placeholder]="dropDownPlaceHolderText" />
  
  <img *ngIf="!filterText" class="ddl__action ddl__action--caret" src="/assets/dropdown-chevron.svg" (click)="expand()" />
  <img *ngIf="filterText" class="ddl__action ddl__action--reset" src="/assets/dropdown-cross.svg" (click)="reset()"/>
  
  <div #ddlList class="ddl__list">
    <div #ddlItem *ngFor="let item of filteredOptions" (click)="choose(item)" class="ddl__item" [ngClass]="{'selected' : item.isSelected}">
      <p>
        <small class="ddl__item-grouping" *ngIf="item.grouping">{{item.grouping}}</small>
        <br *ngIf="item.grouping">
        <span class="ddl__item-text">{{item.value}}</span>
      </p>
    </div>
  </div>
    
</div>
