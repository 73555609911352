import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService } from '@azure/msal-angular';
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from './msal/msal-instance-factories';
import { MsalAuthenticationGuard } from './guards/msal-auth-guard';
import { MsalAuthenticationService } from './msal/msal-authentication.service';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { LoginComponent } from '@app/pages/login/login.component';
import { LogoutComponent } from '@app/pages/logout/logout.component';
import { SegIdentityGuard } from './guards/seg-identity-guard';
import { DualAuthGuard } from './guards/dual-auth-guard';
import { SharedModule } from '@app/shared/shared.module';
import { LoginUnauthorisedComponent } from '@app/pages/login-unauthorised/login-unauthorised.component';



@NgModule({
  declarations: [LoginComponent, LoginUnauthorisedComponent, LogoutComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    MsalAuthenticationGuard,
    SegIdentityGuard,
    DualAuthGuard,
    // CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalAuthenticationService]
})
export class AuthModule { }
