import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditTrackerService {

  public editMade: boolean = false;

  constructor() { }

  public clearTracker(){
    this.editMade = false;
  }

}
