<div ddl class="dropdown-typeahead ddl">
  
  <input readonly #ddlInput class="ddl__input" type="text" [(ngModel)]="selectedItemText" (click)="expand()" (keydown)="onKeyDown($event)" [placeholder]="dropDownPlaceHolderText" />
  
  <img class="ddl__action ddl__action--caret" src="/assets/dropdown-chevron.svg" (click)="expand()" />
  
  <div #ddlList class="ddl__list">
    <div #ddlItem *ngFor="let item of options" (click)="choose(item)" class="ddl__item" [ngClass]="{'selected' : item == selectedItem}">
      <p>
        <small class="ddl__item-grouping" *ngIf="item.grouping">{{item.grouping}}</small>
        <br *ngIf="item.grouping">
        <span class="ddl__item-text">{{item.value}}</span>
      </p>
    </div>
  </div>
    
</div>