import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalAuthenticationGuard } from './msal-auth-guard';
import { SegIdentityGuard } from './seg-identity-guard';
import { FeaturesService } from '@app/shared/services/features.service';

@Injectable()
export class DualAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private segIdentityGuard: SegIdentityGuard,
    private msalAuthenticationGuard: MsalAuthenticationGuard
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    

    if(FeaturesService.isConsultantUrl()){
      return this.msalAuthenticationGuard.canActivate(route, state, true);  
    }
    console.log('route',route);
    

    return this.segIdentityGuard.canActivate(route, state, true);
    
  }
}
