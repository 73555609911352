import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewPageComponent } from './pages/review.page/review.page.component';
import { SkillsCaptureRoutingModule } from './skills-capture-routing.module';
import { FormWrapperComponent } from './components/form-wrapper/form-wrapper.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { StepNavigationComponent } from './components/step-navigation/step-navigation.component';
import { CategoryFormComponent } from './components/category-form/category-form.component';
import { FieldSwitcherComponent } from './components/field-switcher/field-switcher.component';
import { FormRadioGroupComponent } from './components/form-radio-group/form-radio-group.component';
import { FormToggleComponent } from './components/form-toggle/form-toggle.component';
import { FieldLockComponent } from './components/field-lock/field-lock.component';
import { FormRadioComponent } from './components/form-radio/form-radio.component';
import { HomepageLayoutComponent } from 'src/app/_layout/homepage-layout/homepage-layout.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { FormDropdownComponent } from './components/form-dropdown/form-dropdown.component';
import { SkillEditFormComponent } from './components/skill-edit-form/skill-edit-form.component';
import { FormSelectDropdownComponent } from './components/form-select-dropdown/form-select-dropdown.component';
import { DebugPageComponent } from './pages/debug.page/debug.page.component';



@NgModule({
  declarations: [
    ReviewPageComponent,
    DebugPageComponent,
    FormWrapperComponent,
    StepNavigationComponent,
    CategoryFormComponent,
    FieldSwitcherComponent,
    FormRadioGroupComponent,
    FormToggleComponent,
    FieldLockComponent,
    FormRadioComponent,
    FormDropdownComponent,
    FormSelectDropdownComponent,
    HomepageLayoutComponent,
    SkillEditFormComponent
  ],
  imports: [
    CommonModule,
    SkillsCaptureRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule
  ]
})
export class SkillsCaptureModule { }
