import { Injectable } from '@angular/core';
import { Theme } from '@app/shared/models/enums/theme';
import { ThemeService } from '@app/shared/services/theme.service';
import { environment } from '@env/environment';
import { User, UserManager } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root'
})
export class SegIdentitySsoService {

  userManager: UserManager | undefined;

  constructor(private theme: ThemeService) {
    let stsAuthority = "";
    let stsClientId = "";

    theme.getActiveTheme().subscribe({
      next: (theme) => {
        switch (theme) {
          case Theme.TeachingPersonnel:
            stsAuthority = environment.stsAuthorityTP;
            stsClientId = environment.stsClientIdTP;
            break;
          case Theme.ProtocolEducation:
            stsAuthority = environment.stsAuthorityPE;
            stsClientId = environment.stsClientIdPE;
            break;
          case Theme.Fleet:
            stsAuthority = environment.stsAuthorityFES;
            break;
          default:
            stsAuthority = environment.stsAuthorityTP;
            break;
        }

        const settings = {
          authority: stsAuthority,
          client_id: stsClientId,
          redirect_uri: window.location.origin + "/login",
          post_logout_redirect_uri: window.location.origin + "/login",
          unauthorized_route: window.location.origin + "/login-unauthorised",
          response_type: "code",
          scope: "openid profile tenant roles"
        };
        this.userManager = new UserManager(settings);
      }
    })
    
  }

  public getUser(): Promise<User | null> {
    if (this.userManager) {
      return this.userManager.getUser();
    }
    return Promise.reject();
  }

  public login(): Promise<void> {
    if (this.userManager) {
      return this.userManager.signinRedirect();
    }
    return Promise.reject();
  }

  public logout(): Promise<void> {
    if (this.userManager) {
      return this.userManager.signoutRedirect();
    }
    return Promise.reject();
  }

  public signInCallback(): Promise<User> {
    if (this.userManager) {
      return this.userManager.signinRedirectCallback();
    }
    return Promise.reject();
  }
}
