<div class="category-form form">
    <h1>{{ category.headerText | yourtheir }}</h1>

    <div class="form-field" *ngIf="showDropdown">
        <div class="field-group">
            <app-form-dropdown [options]="dropDownSkills" (onDropDownSelectionChange)="selectSkill($event, true)" [categoryName]="category.name"></app-form-dropdown>
        </div>
    </div>

    <div class="header-text" *ngIf="category.description">
        {{ category.description | yourtheir }}
    </div>

    <ng-container *ngIf="getCategories(groupedObjects).length > 0">

        <div *ngFor="let cat of getCategories(groupedObjects)">
            <h2 *ngIf="cat != 'null'" class="cf__category-title">{{ cat }}</h2>
    
            <div #skillItem *ngFor="let skill of groupedObjects[cat]; let i = index" class="form-panel form-panel--highlighted cf__skill" [ngClass]="{'cf__skill--dirty' : (skill.dirty && category.categoryType != 'ShowAll'), 'form-panel--disabled' : !skill.isSelected}">
                <div class="form-panel__header">
                    <label>{{ skill.name }}</label>
                    <app-field-lock *ngIf="skill.isLocked" [appTooltip]="{text: 'This skill has been locked by one of our Consultants.', size: 'fit'}"></app-field-lock>
                    <app-form-toggle *ngIf="!skill.isLocked" (valueChanged$)="onSkillToggled($event, skill, skillItem)" [isChecked]="skill.isSelected"></app-form-toggle>
                </div>
        
                <!-- If unselected (whether pristine or dirty)... -->
        
                <div class="form-panel__subheader" *ngIf="!skill.isSelected && skill.dirty">
                    <div class="pagelet  pagelet--centered">
                        <i app-svg-icon name="cross-exception" class="cf-skill__status-icon" classList="icon  icon-16"></i>
                        <label>This {{(category.name).slice(0,-1) | lowercase }} is unselected.</label>
                    </div>
                </div>
                <div class="form-panel__subheader" *ngIf="!skill.isSelected && !skill.dirty">
                    <div class="pagelet  pagelet--centered">
                        <label>Enable this skill to select your level.</label>
                    </div>
                </div>
            
                <!-- If selected... -->
        
                <div class="form-panel__options" *ngIf="skill.isSelected && (category.categoryOptions && category.categoryOptions.length > 0)">
                    <div class="form-panel__subheader  form-panel__subheader--selected" >
                        <label>{{ skill.headerText }}</label>
                    </div>
                    <app-field-switcher [type]="category.fieldType" [options]="category.categoryOptions" [skill]="skill" (valueChanged$)="onOptionChange($event, skill)"></app-field-switcher>
                </div>
            </div>
        </div>
      </ng-container>



      <ng-container *ngIf="getCategories(groupedObjects).length == 0">

        <div #skillItem *ngFor="let skill of selectedSkills; let i = index" class="form-panel form-panel--highlighted cf__skill" [ngClass]="{'cf__skill--dirty' : (skill.dirty && category.categoryType != 'ShowAll'), 'form-panel--disabled' : !skill.isSelected, 'form-panel--flex-column': isLockedSkill(skill)}">
            <div class="form-panel__header">
                <label>{{ skill.name }}</label>
                <app-field-lock *ngIf="skill.isLocked" [appTooltip]="{text: 'Tsshis skill has been locked by one of our Consultants.', size: 'fit'}"></app-field-lock>
                <app-form-toggle *ngIf="!skill.isLocked" (valueChanged$)="onSkillToggled($event, skill, skillItem)" [isChecked]="skill.isSelected"></app-form-toggle>
            </div>

            <!-- If unselected (whether pristine or dirty)... -->

            <div class="form-panel__subheader" *ngIf="!skill.isSelected && skill.dirty">
                <div class="pagelet  pagelet--centered">
                    <i app-svg-icon name="cross-exception" class="cf-skill__status-icon" classList="icon  icon-16"></i>
                    <label>This {{(category.name).slice(0,-1) | lowercase }} is unselected.</label>
                </div>
            </div>
            <div class="form-panel__subheader" *ngIf="!skill.isSelected && !skill.dirty">
                <div class="pagelet  pagelet--centered">
                    <label>Enable this {{(category.name).slice(0,-1) | lowercase }} to select your level.</label>
                </div>
            </div>
        
            <!-- If selected... -->

            <div class="form-panel__options" *ngIf="skill.isSelected && (category.categoryOptions && category.categoryOptions.length > 0)">
                <div class="form-panel__subheader  form-panel__subheader--selected" >
                    <label>{{ skill.headerText }}</label>
                </div>
                <app-field-switcher [type]="category.fieldType" [options]="category.categoryOptions" [skill]="skill" (valueChanged$)="onOptionChange($event, skill)"></app-field-switcher>
            </div>

            <!-- Footer if locked -->
            <div class="form-panel__footer" *ngIf="isLockedSkill(skill) && isEducator">
                <div class="pagelet pagelet--centered">
                    <app-field-lock [classList]="'field-lock--bordered'"></app-field-lock>
                    <span>{{category.lockTextLong}}</span>
                  </div>
            </div>
        </div>

    </ng-container>



    

    <div *ngIf="!selectedSkills || (selectedSkills && selectedSkills.length == 0)" class="form-panel form-panel--highlighted" >
        <div class="form-panel__header">
            <label>You haven't selected any {{ category.name.toLocaleLowerCase() }} yet.</label>
        </div>
        <div class="form-panel__subheader" >
            <p>
                To add {{ category.name.toLocaleLowerCase() }} to your profile, click the "Select your {{ category.name.toLocaleLowerCase() }}" dropdown and choose from the list of available options.
            </p> 
        </div>
    </div>
</div>
