import { AfterViewInit, Component, ElementRef, HostListener, Input, Renderer2, ViewChild, OnDestroy, } from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

const openTimeMs = 500;
const closeTimeMs = 250;
const shakeTimeMs = 500;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit, OnDestroy {

  private escapeTimeout: any;

  @ViewChild("modalRoot") modalRoot: ElementRef;
  @ViewChild("modalWindow") modalWindow: ElementRef;
  @ViewChild("closeButton", { static: false, read: ElementRef }) closeButton: ElementRef;

  windowState$ = this.modalService.windowState$;
  private windowStateSubscription: Subscription;


  constructor(
    private el: ElementRef,
    public modalService: ModalService,
    private renderer: Renderer2
  ) { }


  public ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.windowStateSubscription = this.windowState$.subscribe(state => {

      if (this.modalService.width) {
        this.renderer.setStyle(this.modalWindow.nativeElement, 'width', this.modalService.width);
      } else {
        this.renderer.setStyle(this.modalWindow.nativeElement, 'width', '600px');
      }

      if (this.modalRoot && this.modalRoot.nativeElement) {
        this.modalRoot.nativeElement.scrollTop = 0;
      }
    });

    this.renderer.setStyle(this.modalWindow.nativeElement, 'width', this.modalService.width + 'px');
  }

  
  public ngOnDestroy(){
    if(this.windowStateSubscription){
      this.windowStateSubscription.unsubscribe();
    }
  }

  @HostListener('mousedown', ['$event']) handleMousedown(event: any) {
    if (event.target.className === 'modal__bg') {
      if (this.modalService.explicitClose == false) {
        event.target.scrollTop = 0;
        this.closeModal();
      } else {
        this.shakeCloseBtn();
      }
    }
  }

  @HostListener('document:keyup', ['$event']) handleKeyUp(event: any) {
    if (event.keyCode === 27) {
      if (this.modalService.explicitClose == false) {
        this.modalRoot.nativeElement.scrollTop = 0;
        this.closeModal();
      } else {
        this.shakeCloseBtn();
      }
    }
  }

  @HostListener('scroll', ['$event']) onScroll(event: any) {
    let modalTop = this.modalRoot.nativeElement.scrollTop;
    let modalBodyOffsetTop = this.modalWindow.nativeElement.offsetTop;

    let closeButton = this.closeButton.nativeElement;
    let closeButtonHeight = closeButton.clientHeight;
    let closeButtonTop = closeButton.offsetTop;

    let buttonScroll = modalBodyOffsetTop + closeButtonHeight + closeButtonTop;


    if (modalTop > buttonScroll) {
      closeButton.classList.add("modal__close--offscreen");
    } else {
      closeButton.classList.remove("modal__close--offscreen");
    }
  }



  public shakeCloseBtn(): void {

    clearTimeout(this.escapeTimeout);
    this.renderer.addClass(this.closeButton.nativeElement, "shake");
    this.renderer.addClass(this.closeButton.nativeElement, "animated");

    this.escapeTimeout = setTimeout(() => {
      this.renderer.removeClass(this.closeButton.nativeElement, 'shake');
      this.renderer.removeClass(this.closeButton.nativeElement, 'animated');
    }, shakeTimeMs);

  }

  public closeModal() {
    this.modalService.closeWindow();
  }

}
