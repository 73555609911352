import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { Skill } from '@app/shared/models/api/skill-model';
import { Subject } from 'rxjs';
import { CategoryOption } from 'src/app/shared/models/api/category-options-model';

@Component({
  selector: 'app-form-radio-group',
  templateUrl: './form-radio-group.component.html',
  styleUrls: ['./form-radio-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormRadioGroupComponent {

  @Input('options') options: CategoryOption[];
  @Input('skill') skill: Skill;

  @Output() radioChanged$ = new Subject<number>();

  constructor() {
  }


  public onRadioChange($eventValue: number){

    this.radioChanged$.next($eventValue);
    
  }

}
