import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NAV_URLS } from './shared/constants/url-constants';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { DualAuthGuard } from './_auth/guards/dual-auth-guard';
import { LoginUnauthorisedComponent } from './pages/login-unauthorised/login-unauthorised.component';

const routes: Routes = [
  { path: '', redirectTo: NAV_URLS.REVIEW_PAGE, pathMatch: 'full'},
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'login-unauthorised', component: LoginUnauthorisedComponent, pathMatch: 'full' },
  { path: 'logout', component: LogoutComponent, pathMatch: 'full' },
  { path: NAV_URLS.REVIEW_PAGE, loadChildren: () => import('./_features/skills-capture/skills-capture.module').then(m => m.SkillsCaptureModule), canActivate: [DualAuthGuard] },
  { path: '404', component: PageNotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
