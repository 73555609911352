import { Component, Input, SimpleChanges } from '@angular/core';
import { Theme } from '@app/shared/models/enums/theme';
import { UserTypeEnum } from '@app/shared/models/enums/user-type';

@Component({
  selector: 'app-profile-badge',
  templateUrl: './profile-badge.component.html',
  styleUrl: './profile-badge.component.scss'
})
export class ProfileBadgeComponent {

  @Input("role") userType: UserTypeEnum;
  @Input("company") userCompany: Theme;
  @Input("id") userId: number | string;
  public UserDomain: string;
  public UserType = UserTypeEnum;


  constructor() { }

  ngOnInit(): void {
    this.getCompany();
  }


  ngOnChanges(changes: SimpleChanges) {
    this.getCompany();

  }

  public getCompany() {
    switch (this.userCompany) {
      case Theme.TeachingPersonnel:
        this.UserDomain = 'teachingpersonnel.com';
        break;
      case Theme.ProtocolEducation:
        this.UserDomain = 'protocol-education.com';
        break;
      case Theme.Fleet:
        this.UserDomain = 'fleeteducationservice.com';
        break;

      default:
        break;
      //todo: 
    }
  }
}
