
<div #modalRoot class="modal" (scroll)="onScroll($event)"
[ngClass]="{
    'modal--open': (modalService.windowState$ | async) == 'open',
    'modal--closing': (modalService.windowState$ | async) == 'closing',
    'modal--closed': (modalService.windowState$ | async) == 'closed'
  }" >
  <div #modalBg class="modal__bg" (click)="modalService.explicitClose ? shakeCloseBtn() : closeModal()"></div>

  <div #modalWindow class="modal__window">
    <div class="modal__app-bar">
      <div class="modal__title pagelet">
        <ng-container [ngSwitch]="modalService.modalType">
          <i app-svg-icon *ngSwitchCase="1" name="info" classList="icon  icon-32" class="modal__icon modal__icon--info"></i>
          <i app-svg-icon *ngSwitchCase="2" name="info" classList="icon  icon-32" class="modal__icon modal__icon--warning"></i>
          <i app-svg-icon *ngSwitchCase="3" name="cross-exception" classList="icon  icon-32" class="modal__icon modal__icon--error"></i>
        </ng-container>
        {{modalService.title}}
      </div>
        <div #closeButton class="modal__close" (click)="closeModal()" *ngIf="modalService.template">
        <i app-svg-icon name="cross" classList="icon  icon-16" class="modal__close-button" (click)="closeModal()"></i>
        <span class="modal__close-label">Close</span>
      </div>
    </div>
    <div class="modal__content" *ngIf="modalService.template">
      <ng-container *ngTemplateOutlet="modalService.template"></ng-container>
    </div>
    <div class="modal__content" *ngIf="!modalService.template">
      {{modalService.message}}
    </div>
    <div class="modal__actions mt-16" *ngIf="!modalService.template">
      <div class="btn-group  btn-group--right mb-0">
        <button class="btn btn--reduced" (click)="closeModal()">OK</button>
      </div>
    </div>
  </div>
    
</div>