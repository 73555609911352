import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SegIdentityService } from '../seg-identity/seg-identity.service';
import { AuthenticationService } from '../services/authentication.service';
import { firstValueFrom } from 'rxjs';
import { SegUser } from '@app/shared/models/auth/seg-user-model';

@Injectable()
export class SegIdentityGuard implements CanActivate {

  constructor(
    private router: Router,
    private segIdentityService: SegIdentityService,
    private authService: AuthenticationService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, doRerouting: boolean = true) {
  
    if (localStorage.getItem('currentUser')) {
      let localStorageUser = localStorage.getItem('currentUser')
      if (localStorageUser) {
        let user = JSON.parse(localStorageUser) as SegUser;
        return true;
      }
    }

    if(doRerouting){
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    }
    
    return false;
  }
}
