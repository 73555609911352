import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2, type OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SegIdentitySsoService } from '@app/_auth/seg-identity/seg-sso.service';
import { AuthenticationService } from '@app/_auth/services/authentication.service';
import { SsoUserService } from '@app/_auth/services/sso-user-service';
import { NAV_URLS } from '@app/shared/constants/url-constants';
import { FeaturesService } from '@app/shared/services/features.service';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent implements OnInit {

  public loggedOutMessage: string  =  '';
  public showLoginButton: boolean = false;
  
  constructor(private router: Router,
    private ssoAuth: SegIdentitySsoService,
    public ssoUserService: SsoUserService,
    private spinnerService: SpinnerService,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: any
  ) {
    if(FeaturesService.isLiteUrl()) {
      // this.renderer.setStyle(this.document.documentElement , 'height', 'auto');
      // this.renderer.setStyle(this.document.body, 'height', 'auto');
      
      this.renderer.addClass(this.document.documentElement , 'lite');
      this.renderer.addClass(this.document.body, 'lite');
    }
  }


  ngOnInit(): void {

    if(!FeaturesService.CONSULTANT_VIEW()){
      this.ssoAuth.logout();
    }
   }

   public routeToLogin(){
    this.router.navigate([`./${NAV_URLS.LOGIN_PAGE}`])
   }

}
