import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrl: './form-toggle.component.scss'
})
export class FormToggleComponent {

  @Input('isChecked') isChecked: boolean = false;
  
  @Output() valueChanged$ = new Subject<boolean>();

  public onToggleChanged($event: Event) {
    this.valueChanged$.next(this.isChecked);
  }
}
