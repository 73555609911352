import { Component, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { EducatorDetails } from '@app/shared/models/api/educator-model';
import { UserSkillsValue } from '@app/shared/models/api/user-skills-values';
import { FeaturesEnum } from '@app/shared/models/enums/features';
import { DropDownOption } from '@app/shared/models/forms/dropdown-option';
import { EditTrackerService } from '@app/shared/services/edit-tracker.service';
import { FeaturesService } from '@app/shared/services/features.service';
import { Subject } from 'rxjs';
import { Category } from 'src/app/shared/models/api/category-model';
import { Skill } from 'src/app/shared/models/api/skill-model';

export function groupByCategoryAlphabetical(objects: Skill[]): { [key: string]: Skill[] } {
  const grouped = objects.reduce((acc: { [key: string]: Skill[] }, obj: Skill) => {
    (acc[obj.grouping] = acc[obj.grouping] || []).push(obj);
    return acc;
  }, {});
 
  // Sort the keys and create a new sorted object
  const sortedGrouped: { [key: string]: Skill[] } = {};
  Object.keys(grouped).sort().forEach(key => {
    sortedGrouped[key] = grouped[key].sort((a, b) => a.name.localeCompare(b.name));
  });

  return sortedGrouped;
}

export function groupByCategory(objects: Skill[]): { [key: string]: Skill[] } {
  return objects.reduce((acc:any, obj) => {
    (acc[obj.grouping] = acc[obj.grouping] || []).push(obj);
    return acc;
  }, {});
}

export function sortAlphabetically(objects: Skill[]): Skill[] {
  return objects.sort((a, b) => a.name.localeCompare(b.name));
}

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss']
})
export class CategoryFormComponent implements OnInit {

  public availableSkills: Skill[] = [];
  public Features = FeaturesEnum;
  public isEducator: boolean = !FeaturesService.isConsultantUrl();


  public get dropDownSkills(): DropDownOption<Skill>[] {
    return this.availableSkills.map(
      (x) =>
        ({ value: x.name, isSelected: false, data: x, grouping: x.grouping } as DropDownOption<Skill>)
    );
  }

  public selectedSkills: Skill[] = [];

  private _dropDownSkill: Skill;
  public get dropDownSkill(): Skill {
    return this._dropDownSkill;
  }
  public set dropDownSkill(value: Skill) {
    this._dropDownSkill = value;

    this.selectSkill(value);
  }

  groupedObjects: { [key: string]: Skill[] } = {};

  @Input('category') category: Category;
  @Input('currentEducator') currentEducator: EducatorDetails;

  public showDropdown: boolean = false;

  @Output() valueChanged$ = new Subject<UserSkillsValue>();

  constructor(public editTracker: EditTrackerService) {
  }

  ngOnInit(): void {
    if (this.category) {
      this.loadCategory(this.category);
    }
  }

  loadCategory(category: Category) {
    this.availableSkills = category.skills;

    switch (category.categoryType) {
      case 'ShowAll':
        this.selectedSkills = this.availableSkills;
        this.selectedSkills.forEach(x => x.dirty = false);
        break;

      case 'Dropdown':
        this.showDropdown = true;
        this.availableSkills.filter(s => s.isSelected).forEach(s => this.selectSkill(s));
        break;
    }
  }

  public selectSkill(selectedSkill: Skill, fireOnChange: boolean = false) {
    selectedSkill.isSelected = true;
    selectedSkill.dirty = false;
    if (!selectedSkill.selectedOption) {
      selectedSkill.selectedOption = this.getDefaultSkillOptionId();
    }

    var index = this.availableSkills.findIndex(x => x.id == selectedSkill.id);
    if (index !== -1) {
      this.availableSkills.splice(index, 1);
    }

    if (fireOnChange) {
      this.selectedSkills.unshift(selectedSkill);
      
      this.onOptionChange(selectedSkill.selectedOption, selectedSkill);
      // Keep the order but push the selected skill's group to the first index
      if (selectedSkill.grouping) {
        let group = this.selectedSkills.filter(skill => skill.grouping === selectedSkill.grouping);
        this.selectedSkills = [...group, ...this.selectedSkills.filter(skill => skill.grouping !== selectedSkill.grouping)
        ];

        this.groupedObjects = groupByCategory(this.selectedSkills);
      } else {
        this.selectedSkills = [selectedSkill, ...this.selectedSkills.filter(skill => skill.id !== selectedSkill.id)];
        this.groupedObjects = groupByCategory(this.selectedSkills);
      }
    } 
    else {

      this.selectedSkills.unshift(selectedSkill);
      this.groupedObjects = groupByCategoryAlphabetical(this.selectedSkills);
    }

    this.editTracker.editMade = true;
  }

  getCategories(groupedObjects: { [key: string]: Skill[] }): string[] {
    return Object.keys(groupedObjects);
  }

  private getDefaultSkillOptionId(): number {

    if (this.category && this.category.categoryOptions && this.category.categoryOptions.length > 0) {
      let dispOrders = this.category.categoryOptions.map(x => x.displayOrder);
      let maxDisplayOrder = Math.max(...dispOrders);
      let defaultIndex = this.category.categoryOptions.findIndex(x => x.displayOrder == maxDisplayOrder);

      return this.category.categoryOptions[defaultIndex].id;
    }

    return null;
  }

  public onSkillToggled($eventValue: boolean, contextSkill: Skill, skillItem: HTMLElement) {

    if (contextSkill) {
      contextSkill.isSelected = $eventValue;

      if (contextSkill.isSelected && !contextSkill.selectedOption) {
        contextSkill.selectedOption = this.getDefaultSkillOptionId();
      }

      this.onOptionChange(contextSkill.isSelected ? contextSkill.selectedOption : null, contextSkill);
    }

    contextSkill.dirty = true;
    this.editTracker.editMade = true;
  }
    
  public onOptionChange($eventValue: number, contextSkill: Skill) {
      
    if (contextSkill) {
      contextSkill.selectedOption = $eventValue;
      var partialUserSkillValue = { skillId: contextSkill.id, optionId: contextSkill.selectedOption, isSelected: contextSkill.isSelected } as UserSkillsValue;
      this.valueChanged$.next(partialUserSkillValue);
      this.editTracker.editMade = true;
    }
  }

  public categoryHasLockedSkill(category: Category) {
    return category.skills.some(x => x.isLocked == true);
  }
  public isLockedSkill(skill: Skill) {
    return skill.isLocked;
  }


}
