import { Component, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-saving-indicator',
  templateUrl: './saving-indicator.component.html',
  styleUrl: './saving-indicator.component.scss'
})
export class SavingIndicatorComponent implements OnInit, OnDestroy {
  
  public saveState: boolean;
  private saveStateSubscription: Subscription;

  constructor(
    public spinnerService: SpinnerService) { }

  ngOnInit() {
    this.spinnerService.showSaving
    this.saveStateSubscription = this.spinnerService.saveState$.subscribe(state => {
      this.saveState = state;
    });
  }


  ngOnDestroy(): void {
    if (this.saveStateSubscription) {
      this.saveStateSubscription.unsubscribe();
    }
  }

}
