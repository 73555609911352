import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { AuthenticatedUser } from "@app/shared/models/api/authenticated-user-model";
import { TokenResponse } from "@app/shared/models/api/token-response";
import { environment } from "@env/environment";
import { Subject, catchError, tap, Observable } from "rxjs";
import { MsalAuthenticationService } from "../msal/msal-authentication.service";
import { Theme } from "@app/shared/models/enums/theme";
import { ThemeService } from "@app/shared/services/theme.service";
import { SegIdentityService } from "../seg-identity/seg-identity.service";
import { FeaturesService } from "@app/shared/services/features.service";


@Injectable({
  providedIn: 'root'
  
})
export class AuthenticationService implements OnDestroy, OnInit {
  public authenticationComplete: Subject<boolean> = new Subject<boolean>();
  public triedMsal: boolean = false;

  private usingMsalAuth: boolean = false;

  private ngUnsubscribe = new Subject();

  constructor(
    private msalService: MsalAuthenticationService,
    private httpClient: HttpClient,
    private themeService: ThemeService,
    private ssoService: SegIdentityService

  ) {
  }
  ngOnInit(): void {
    this.msalService.authenticatedUserUpdated.subscribe( {
      next: (user: AuthenticatedUser) => {
        this.ssoService.userInfo = user;
      }
    })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  public initializeMsal(redirectOnLogin: boolean): void {
    this.msalService.initializeMsalService(redirectOnLogin);
  } 

  public initializeMsalPromise(): Promise<void> {
    return this.msalService.initializeMsalService(false);
  }

  public async login(method: string, optionalRedirectPath: string = ""): Promise<void> {
    switch (method) {
      case 'msal':
        this.usingMsalAuth = true;
        await this.msalService.loginRedirect(true, optionalRedirectPath);
        break;

      default:
        this.usingMsalAuth = true;
        this.msalService.loginRedirect(true, optionalRedirectPath);
        break;
    }
  }

  public logout(): void {
    if (this.usingMsalAuth) {
      this.msalService.logout();
    }
    this.ssoService.userInfo = new AuthenticatedUser();
  }

  public getTokensFromAuthApi(token: string) {
    let endpoint = FeaturesService.isConsultantUrl() ? environment.msalConfig.authApi : environment.segIdentityAuthApi; 
    endpoint += endpoint.endsWith("/") ? "" : "/";
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json')
      .set('X-Company-Type', this.themeDecipher())
      .set('Authorization', `Bearer ${token}`);

    return this.httpClient.get<TokenResponse>(endpoint + 'Access/GetAccessToken', { headers: httpHeaders }).pipe(
      catchError(this.handleError('get tokens'))
    );
  }

  public getRefreshTokensFromSegOsCentralAuth(){
    let endpoint = FeaturesService.isConsultantUrl() ? environment.msalConfig.authApi : environment.segIdentityAuthApi;
    endpoint += endpoint.endsWith("/") ? "" : "/";

    let refreshToken = this.ssoService.userInfo.refreshToken;
    if(!refreshToken){
      refreshToken = sessionStorage.getItem("refresh-token");
    }

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json')
      .set('X-Company-Type', this.themeDecipher())
      .set('Authorization', `Bearer ${refreshToken}`);

    return this.httpClient.post<TokenResponse>(endpoint + 'Validation/RefreshAccessToken',
      { "RefreshCode": environment.refreshCode, "RefreshToken": refreshToken }, { headers: httpHeaders }).pipe(
        tap((response: TokenResponse) => {
          this.ssoService.userInfo.token = response.token;
          this.ssoService.userInfo.refreshToken = response.refreshToken;
          this.ssoService.userInfo.tokenValid = true;
        }));
  }


  private handleError(operation = 'operation') {
    return (error: any): Observable<any> => {
      if (error.status == 401) {
        throw new Error("401");
      } else {
        console.error(`${operation} failed: ${error.message}`); // log to console
        throw new Error("Oops, an error has occurred. If the error persists, please contact your local branch.");
      }
    };
  }

  private themeDecipher(): string {
    switch (this.themeService.currentTheme) {
      case Theme.ProtocolEducation:
        return 'PE';
      case Theme.TeachingPersonnel:
        return 'TP';
      case Theme.Fleet:
        return 'FT';
      default:
        return 'PE';
    }
  }
}
