Form 
<div class="form diag">
    <h2>Required</h2>

    <div class="form-field">
        <label class="field-label" for="text-required"><span>Client Name</span></label>
        <div class="field-group">
          <input type="text" id="text-required" formControlName="text-required" placeholder="" class="form-control" autocomplete="off"/>
        </div>
    </div>



    <h2>Disabled</h2>

    <div class="form-field">
        <label class="field-label" for="text-disabled"><span>Client Name</span></label>
        <div class="field-group">
          <input type="text" id="text-disabled" formControlName="text-disabled" placeholder="" class="form-control" autocomplete="off"/>

          <label class="field-description" for="clientName">
            Lorem ipsum dolor sit amet.
          </label>

          <label class="field-error" for="clientName">
            Lorem ipsum dolor sit amet.
          </label>
        </div>
    </div>



    <h2>Error</h2>

    <div class="form-field">
        <label class="field-label" for="text-error"><span>Client Name</span></label>
        <div class="field-group">
          <input [disabled] type="text" id="text-error" formControlName="text-error" placeholder="" class="form-control" autocomplete="off"/>
          <label class="field-error" for="clientName">
            Name cannot be blank
          </label>
        </div>
    </div>


</div>