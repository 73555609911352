import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { SavingIndicatorComponent } from './components/saving-indicator/saving-indicator.component';
import { PosUnderHeaderDirective } from './directives/pos-under-header.directive';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ModalComponent } from './components/modal/modal.component';
import { ContainerQueryDirective } from './directives/container-query.directive';
import { ProfileBadgeComponent } from './components/profile-badge/profile-badge.component';
import { YourTheirPipe } from './pipes/your-their.pipe';
import { ITheirPipe } from './pipes/i-their.pipe';
import { FeaturesPipe } from './pipes/features.pipe';
import { TooltipDirective } from './directives/tooltip.directive';
import { HeaderDropdownComponent } from './components/header-dropdown/header-dropdown.component';
import { MenuService } from './services/menu.service';
import { UtilityBarComponent } from './components/utility-bar/utility-bar.component';
import { UtilityBarService } from './services/utility-bar.service';
import { PersonPipe } from './pipes/person.pipe';
import { ProfileBarComponent } from './components/profile-bar/profile-bar.component';

@NgModule({
  declarations: [
    SvgIconComponent,
    LoadingSpinnerComponent,
    SavingIndicatorComponent,
    PosUnderHeaderDirective,
    SpinnerComponent,
    ModalComponent,
    ContainerQueryDirective,
    ProfileBadgeComponent,
    ProfileBarComponent,
    YourTheirPipe,
    ITheirPipe,
    FeaturesPipe,
    TooltipDirective,
    HeaderDropdownComponent,
    UtilityBarComponent,
    PersonPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    MenuService,
    UtilityBarService
  ],
  exports: [
    SvgIconComponent,
    LoadingSpinnerComponent,
    SavingIndicatorComponent,
    PosUnderHeaderDirective,
    SpinnerComponent,
    ModalComponent,
    ContainerQueryDirective,
    ProfileBadgeComponent,
    ProfileBarComponent,
    YourTheirPipe,
    ITheirPipe,
    FeaturesPipe,
    TooltipDirective,
    HeaderDropdownComponent,
    UtilityBarComponent, 
    PersonPipe
  ]
})
export class SharedModule { }
