import { Injectable } from '@angular/core';
import { AuthenticatedUser } from '@app/shared/models/api/authenticated-user-model';
import { TokenResponse } from '@app/shared/models/api/token-response';
import { SegUser } from '@app/shared/models/auth/seg-user-model';

@Injectable({
  providedIn: 'root'
})
export class SegIdentityService {
  
  public userInfo: AuthenticatedUser = new AuthenticatedUser();

  public setUserInfo(info: SegUser, token: string = '') {
    this.userInfo = new AuthenticatedUser();
    this.userInfo.id = info.id;
    this.userInfo.userEmail = info.email;
    this.userInfo.refreshToken = null;
    this.userInfo.token = token;
    this.userInfo.identityToken = token;
    this.userInfo.tokenInvalid = true;
    this.userInfo.authenticated = true;
  }

  public setInfoFromToken(response: TokenResponse) {
    this.userInfo.token = response.token;
    this.userInfo.refreshToken = response.refreshToken;
    sessionStorage.setItem("token", response.token);
    sessionStorage.setItem("refresh-token", response.refreshToken);
  }

}