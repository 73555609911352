import { DOCUMENT } from '@angular/common';
import { ComponentRef, Inject, Injectable, Renderer2, RendererFactory2, TemplateRef } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { ModalType } from '../models/enums/modal-type';
import { SpinnerService } from './spinner.service';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private _windowStateSubject = new BehaviorSubject<string>('closed');
  windowState$ = this._windowStateSubject.asObservable();

  // setWindowState(newState: string): void {
  //   this.windowStateSubject.next(newState);
  // }

  get windowState() {
    return this._windowStateSubject.getValue();
  }
  set windowState(state: string) {
    this._windowStateSubject.next(state);
  }



  private modlaState = new Subject<boolean>();
  public modalState$ = this.modlaState.asObservable();
  private componentRef: ComponentRef<any>;

  public templates: Set<TemplateRef<any>> = new Set<TemplateRef<any>>();
  public template: TemplateRef<any>;

  public title: string;
  public message: string;
  public modalType: ModalType = ModalType.Default;
  public explicitClose: boolean;
  public width: string;

  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private spinnerService: SpinnerService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }



  public openWindow(
    template: TemplateRef<any>,
    title: string,
    explicitClose: boolean,
    modalType?: ModalType,
    width?: string
  ): void {
    this.template = template;
    this.title = title;
    this.modalType = modalType | ModalType.Default;
    this.explicitClose = explicitClose;
    this.width = width;
    this.windowState = 'open';
    this.renderer.addClass(this.document.body, 'modal-window--open');
    this.scrollfix();
  }

  public closeWindow(forceClose = false): void {
    this.windowState = 'closing';

    // Give time for CSS to fade-out the spinner,
    setTimeout(() => {
      this.windowState = 'closed';
      this.template = null;
      this.renderer.removeClass(this.document.body, 'modal-window--open');
      this.renderer.removeClass(this.document.body, 'modal-window--scrollfix');

    }, forceClose ? 0 : environment.spinDownDuration);
  }

  public showErrorDialog(title: string, message: string) {
    if (this.windowState != 'closed') {
      this.closeWindow(true);
    }
    setTimeout(() => {
      this.spinnerService.hideSpinner();
      this.showDialogBox(title, message, true, ModalType.Error);
    }, environment.spinDownDuration);
  }

  public showWarningDialog(title: string, message: string,) {
    if (this.windowState != 'closed') {
      this.closeWindow(true);
    }
    setTimeout(() => {
      this.spinnerService.hideSpinner();
      this.showDialogBox(title, message, true, ModalType.Warning);
    }, environment.spinDownDuration);
  }

  public showInfoDialog(title: string, message: string) {
    if (this.windowState != 'closed') {
      this.closeWindow(true);
    }
    setTimeout(() => {
      this.spinnerService.hideSpinner();
      this.showDialogBox(title, message, true, ModalType.Info);
    }, environment.spinDownDuration);
  }

  public showDialogBox(
    title: string,
    message: string,
    explicitClose?: boolean,
    modalType?: ModalType,
    width?: string
  ): void {
    this.title = title;
    this.message = message;
    this.modalType = modalType | ModalType.Default;
    this.explicitClose = explicitClose;
    this.width = width;
    this.windowState = 'open';

    this.renderer.addClass(this.document.body, 'modal-window--open');
    this.scrollfix();
  }

  public scrollfix() {
    // This stops the page from jumping to the right due to the scrollbar being hidden.
    const clientHeight = this.document.body.clientHeight;
    const scrollHeight = this.document.body.scrollHeight;

    if (scrollHeight > clientHeight) {
      this.renderer.addClass(this.document.body, 'modal-window--scrollfix');
    }
  }
}
