<div class="overview ov">
  
  <div #intro class="overview__intro" *ngIf="(!(Features.CONSULTANT_VIEW | FEATURES) && currentEducator)">

    <ng-container *ngIf="editTracker.editMade == false">
      <p class="overview__intro-title">Hi {{currentEducator.firstName}}</p>
      <p class="overview__intro-text">By completing your skills profile it
        empowers us to showcase your expertise and unique qualifications to
        schools seeking educators like you.
      </p>
    </ng-container>

    <ng-container *ngIf="editTracker.editMade == true">
      <p class="overview__intro-title">Thank you {{currentEducator.firstName}}!</p>
      <p class="overview__intro-text">Keeping your profile up to date will help us to find you the best placements.</p>
    </ng-container>
  </div>

  <app-profile-bar [currentEducator]="currentEducator"></app-profile-bar>

  <div *ngIf="formData | async as form">

    <div class="overview__sections">
      <ng-container *ngFor="let category of form.categories; let i = index">
        
        <!-- LOADING DATA -->
        <section #sectionLoading class="acc skills-section ss--{{i}} ss--loading" *ngIf="category.skills.length == 0">
          <app-loading-spinner></app-loading-spinner>
        </section>

        <!-- EMPTY DATA -->
        <section #sectionEmpty class="acc skills-section ss--{{i}} ss--{{category.categoryRoute | lowercase}} ss--empty" 
        *ngIf="!categoryHasUserData(category) && category.skills.length > 0"
        (click)="(isEducator || (!isEducator && currentEducator.isPending)) ? navigateToForm(category.categoryRoute) : null">
          <div class="pagelet  ss__heading">
            <h2 class="ss__title" *ngIf="category.headerText">
              <i *ngIf="isEducator || (!isEducator && currentEducator.isPending)" app-svg-icon name="circle-plus" classList="icon  icon-24" class="ss__title-icon"></i> 
              {{category.headerText | person: false : (Features.CONSULTANT_VIEW | FEATURES) : currentEducator.firstName : (category.name | lowercase)}}
            </h2>
          </div>
          <div class="ss__cta  ss__cta--{{i+1}}" [ngClass]="{'ss__cta--clickable' : isEducator}"></div>
        </section>

        <!-- WITH DATA -->

        <section #sectionPopulated class="acc skills-section ss--has-data ss--has-data ss--{{i}} ss--{{ category.categoryRoute | lowercase }}" *ngIf="categoryHasUserData(category) && category.skills.length > 0" 
          [appContainerQuery]="{
            className: 'ss__mobile',
            operator: 'lt',
            breakpoint: 350
          }">
          <h2 class="ss__title" (click)="isEducator || (!isEducator && currentEducator.isPending) ? navigateToForm(category.categoryRoute) : null" class="cursor-pointer">
            {{category.description | person: true : (Features.CONSULTANT_VIEW | FEATURES) : currentEducator.firstName : (category.name | lowercase)}}
          </h2>

          <div class="skill-list">
            <ng-container *ngFor="let skill of filterSelectedSkills(category.skills)">
              <ng-container *ngIf="!skill.selectedOption">
                <div class="sl-item sl-item--single pagelet pagelet--centered">
                  <div class="sl__tick"></div>
                  <div class="sl__skill">{{ skill.name }}</div>

                  <div *ngIf="(Features.SKILL_ITEM_EDIT | FEATURES) && !currentEducator.isPending" class="sl__edit pagelet  pagelet--centered  pagelet--right  pagelet--right-all" (click)="consultantEditSkill(category, skill)">
                    <span class="sl__edit-label">Edit</span>
                    <i app-svg-icon name="os-edit" classList="icon  icon-16"></i> 
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="skill.selectedOption">
                <table id="table" (click)="(!skill.isLocked && isEducator) || (!isEducator && currentEducator.isPending) ? navigateToForm(category.categoryRoute) : null" [ngClass]="skill.isLocked ? 'cursor-default' : 'cursor-pointer'">
                  <tr>
                    <td class="sl__skill">{{skill.name}}:</td>
                    <td>
                      <ng-container *ngIf="skill.isLocked">
                        <div class="sl__level  sl__level--locked  sl__level--{{getSkillLevelOrder(skill.selectedOption, category.categoryOptions)}}"
                          [appTooltip]="{text: (Features.SKILL_ITEM_EDIT | FEATURES) ? 'This shows as locked for the educator when edited by a consultant.' : 'This skill has been locked by one of our Consultants', size: 'md'}">
                          {{getSkillLevelName(skill.selectedOption, category.categoryOptions)}}
                          <app-field-lock [classList]="'field-lock--bordered'"></app-field-lock>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!skill.isLocked">
                        <div class="sl__level  sl__level--{{getSkillLevelOrder(skill.selectedOption, category.categoryOptions)}}">
                          {{getSkillLevelName(skill.selectedOption, category.categoryOptions)}}
                        </div>
                      </ng-container>
                    </td>
                    <td class="sl__edit" *ngIf="(Features.SKILL_ITEM_EDIT | FEATURES) && !currentEducator.isPending">

                      <div class="sl__edit pagelet  pagelet--centered  pagelet--right  pagelet--right-all" (click)="consultantEditSkill(category, skill)">
                        <span class="sl__edit-label">Edit</span>
                        <i app-svg-icon name="os-edit" classList="icon  icon-16"></i> 
                      </div>

                    </td>
                  </tr>
                </table>
              </ng-container>
              
            </ng-container>
            
          </div>
          <div *ngIf="(Features.SKILL_NAV | FEATURES) || ((Features.CONSULTANT_VIEW | FEATURES) && currentEducator.isPending)" class="sl__edit pagelet  pagelet--centered  pagelet--right  pagelet--right-all" (click)="navigateToForm(category.categoryRoute)">
            <div>Edit</div>
            <i app-svg-icon name="os-edit" classList="icon  icon-16"></i>
          </div>

          <div class="ss__lock-tip" *ngIf="isEducator && categoryHasLockedSkill(category)">
            <div class="pagelet pagelet--centered">
              <app-field-lock [classList]="'field-lock--bordered'"></app-field-lock>
              <span>{{category.lockTextShort}}</span>
            </div>
          </div>

          <div class="ss__edit-tip" *ngIf="((Features.CONSULTANT_VIEW | FEATURES) && !currentEducator.isPending)">
            <div class="pagelet pagelet--centered">
              <i app-svg-icon name="info" classList="icon  icon-24"></i>
              <span>{{category.editModeText}}</span>
            </div>
          </div>

        </section>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #rcEditModal>
  <app-skill-edit-form [formId]="formData.value.id" [category]="modalCategory" [skillId]="modalSkillId" (onDataChanged)="reload()"></app-skill-edit-form>
</ng-template>


<ng-template #utilityBarForm>
  <div class="overview__email-educator" *ngIf="(Features.CONSULTANT_VIEW | FEATURES) && (emailSendStatus | async) as emailStatus">
    
    <div class="overview__send-email pagelet  pagelet--centered" *ngIf="currentEducator && emailStatus.canSendEmail">
      <p>Send {{currentEducator.firstName}} an invite to update their skills.</p>
      <button class="btn" (click)="sendEmailToEducator()">
        <i app-svg-icon name="email2" classList="icon  icon-24" style="height: 24px; width: 24px;"></i> 
        Email Educator</button>
    </div>

    <div class="overview__email-sent pagelet  pagelet--centered" *ngIf="currentEducator && justSentEmail && !emailStatus.canSendEmail">
      <div class="overview__email-sent-icon"></div>
      <p>An invite has been emailed to {{currentEducator.firstName}}.</p>
    </div>

    <div class="overview__email-sent pagelet  pagelet--centered" *ngIf="currentEducator && !justSentEmail && !emailStatus.canSendEmail">
      <div class="overview__email-sent-icon"></div>
      <p>An invite was recently emailed to {{currentEducator.firstName}}. Last sent: {{emailStatus.lastSent | date}}</p>
    </div>
  </div>
</ng-template>
