import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SegIdentityService } from '@app/_auth/seg-identity/seg-identity.service';
import { AuthenticationService } from '@app/_auth/services/authentication.service';
import { SessionUserHashManagerService } from '@app/_auth/services/session-user-hash-manager.service';
import { EmailSentResponse } from '@app/shared/models/api/email-sent-model';
import { ApiBaseService } from '@app/shared/services/api-base.service';
import { ModalService } from '@app/shared/services/modal.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService extends ApiBaseService {

  constructor(
    httpClient: HttpClient,
    authService: AuthenticationService,
    sessionMgrHash: SessionUserHashManagerService,
    segIdentityService: SegIdentityService,
    modalService: ModalService) {
      super(httpClient, authService, sessionMgrHash, segIdentityService, modalService);
  }


  public loadCanEmailEducator(educatorId: number) : Observable<EmailSentResponse> {

    let emailCanSendEndpoint = `${environment.apiUrl}/educator/${educatorId}/Email/Invite/Sent`;

    return this.getData<EmailSentResponse>(emailCanSendEndpoint);
  }

  public sendEducatorInviteEmail(educatorId: number): Observable<boolean> {
    let emailSendEndpot = `${environment.apiUrl}/educator/${educatorId}/Email/Invite`;

    return this.postData<boolean>(emailSendEndpot, null);
  }
}
