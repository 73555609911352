
<div class="login">

  <main class="login__main">

    <div class="login__left">
      <div class="login__form">
        <div class="login__logo">
          <img *ngIf="logo" class="tp-logo" src="../../assets/logos/{{logo}}" />
        </div>
        <div class="login__error">
          Sorry, we could not log you in using the credentials you have supplied.
        </div>
        
        <div class="btn-group">
          <button class="login__login-button" type="button" (click)="doLogout()">Click here to try logging in again</button>
        </div>

      </div>
    </div>
    
    <div class="login__right">
      <div class="login__graphic"></div>
    </div>

  </main>
    
    
</div>  
