import { Pipe, PipeTransform } from '@angular/core';
import { EducatorService } from '@app/_features/skills-capture/services/educator.service';
import { FeaturesService } from '../services/features.service';

@Pipe({
  name: 'yourtheir'
})
export class YourTheirPipe implements PipeTransform {

    constructor(private educatorService: EducatorService) {
        
    }

    transform(value: string): string {

      if(!FeaturesService.isConsultantUrl()){
        return value;
      }

      let firstName = this.educatorService.educatorDetails$.value.firstName;
      
      if (!value || !firstName) {
        return value;
      }
  
      // Define the words to replace and their replacements
      const replacements = [
        { word: 'you', replacement: firstName },
        { word: 'your', replacement: `${firstName}'s` },
        { word: 'yours', replacement: `${firstName}'s` },
        { word: 'I', replacement: firstName },
        { word: 'me', replacement: firstName },
        { word: 'my', replacement: `${firstName}'s` },
        { word: 'mine', replacement: `${firstName}'s` },
        { word: 'we', replacement: firstName },
        { word: 'us', replacement: firstName },
        { word: 'our', replacement: `${firstName}'s` },
        { word: 'ours', replacement: `${firstName}'s` },
        { word: 'am', replacement: 'is' },
        { word: 'are', replacement: 'is' },
        { word: 'were', replacement: 'was' },
        { word: 'have', replacement: 'has' },
        { word: 'do', replacement: 'does' }
      ];
  
      // Replace the words with the appropriate replacements
      replacements.forEach(item => {
        const regex = new RegExp(`\\b${item.word}\\b`, 'gi');
        value = value.replace(regex, item.replacement);
      });
  
      // Adjust spacing after possessive replacements
      value = value.replace(/'s\s+/g, "'s ");
  
      return value;
    }
}