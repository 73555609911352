<div class="profile__user-detail  ud" [ngClass]="badgeSize < 96 ? 'ma' : 'mb'" *ngIf="(Features.CONSULTANT_VIEW | FEATURES) && currentEducator">
    <div class="pagelet  pagelet--centered  ud__pagelet">
      <div #udImage class="ud__badge  badge badge--bordered" [class]="'badge--'+badgeSize">
        <app-profile-badge [role]="userType.Educator" [company]="currentTheme" [id]="currentEducator.id"></app-profile-badge>
      </div>
      <div #udInfo class="ud__info udi">
        <h3><div class="udi__name">{{currentEducator.firstName}} {{currentEducator.lastName}}</div></h3>
        <div class="udi__meta"><div class="udi__email">{{currentEducator.emailAddress}}</div></div>
      </div>
    </div>
  </div>