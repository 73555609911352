import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Directive, ElementRef, HostListener, Input, Renderer2, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appContainerQuery]',
})
export class ContainerQueryDirective implements AfterViewInit {

  @Input("appContainerQuery") cq: cqConfig;
  public componentWidth: number;

  constructor(
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  @HostListener('window:resize')
  resize(): void {
    this.componentWidth = this.el.nativeElement.offsetWidth;
    this.doRender();
    // console.log('CQ::', this.el.nativeElement, this.componentWidth);
    
  }

  ngOnInit(): void {
    this.resize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resize();
  }

  ngAfterViewInit(): void {
    this.resize();
    setTimeout(() => {
      this.resize();
    }, 350);
  }


  public doRender() {

    if (this.cq == null) {
      console.warn('The container query config is missing from the directive!');
      return;
    }
    if (this.cq.className == null || this.cq.className == '') {
      console.warn('The container query config is missing its className!');
      return;
    }
    if (this.cq.breakpoint == null || this.cq.breakpoint < 0) {
      console.warn('The container query breakpoint must be zero or higher!');
      return;
    }
    if (this.componentWidth == 0) {
      return;
    }

    switch (this.cq.operator) {
      case 'lt':
      case '<':
        if (this.componentWidth < this.cq.breakpoint) {
          this.renderer.addClass(this.el.nativeElement, this.cq.className);
        }
        else {
          this.renderer.removeClass(this.el.nativeElement, this.cq.className);
        }
        break;

      case 'gt':
      case '>':
        // console.log(this.componentWidth, this.cq.breakpoint);
        if (this.componentWidth > this.cq.breakpoint) {
          this.renderer.addClass(this.el.nativeElement, this.cq.className);
        }
        else {
          this.renderer.removeClass(this.el.nativeElement, this.cq.className);
        }
        break;

      default:
        console.warn('The container query operator must be one ofthe following: lt | gt | < | >', this.el.nativeElement);
        return;
        break;
    }
  }

}

export class cqConfig {
  className: string;  // Any class name.
  operator: string;   // lt | gt | < | >
  breakpoint: number; // Pixel value to check.
}