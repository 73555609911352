import { AfterViewInit, Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPosUnderHeader]',
})
export class PosUnderHeaderDirective implements AfterViewInit {


  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  @HostListener('window:scroll')
  scroll(): void {
    this.rePosition();
  }
  
  ngAfterViewInit(): void {
    this.rePosition();
  }

  public rePosition() {

    let header: HTMLElement = document.querySelector('.l-header');
    if (header) {
      let height = header.offsetHeight;


      if (window.scrollY < height) {
        this.renderer.setStyle(this.el.nativeElement, 'top', height - window.scrollY + 15 + 'px');
      }
      else {
        this.renderer.setStyle(this.el.nativeElement, 'top', 15 + 'px');
      }
    }


  }


}
