import { Component, Input, Output } from '@angular/core';
import { Skill } from '@app/shared/models/api/skill-model';
import { FeaturesEnum } from '@app/shared/models/enums/features';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrl: './form-radio.component.scss'
})
export class FormRadioComponent {

  public get name(): string {
    return this.skill?.name.replace(/ /g,'_').toLocaleLowerCase(); // For example, converting it to uppercase;
  }

  public Features = FeaturesEnum;

  @Input('value') value: number;

  @Input('skill') skill: Skill;

  @Output() radioChanged$ = new Subject<number>();

  @Input('isChecked') isChecked: boolean = false;

  public onRadioChanged($event: any){

    if(this.value > 0){
      this.skill.isSelected = true;
      this.radioChanged$.next(this.value);
    }
    
  }
}
