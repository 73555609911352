import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageLayoutComponent } from '@app/_layout/homepage-layout/homepage-layout.component';
import { NAV_URLS } from '@app/shared/constants/url-constants';
import { FormWrapperComponent } from './components/form-wrapper/form-wrapper.component';
import { ReviewPageComponent } from './pages/review.page/review.page.component';
import { SkillEditFormComponent } from './components/skill-edit-form/skill-edit-form.component';
import { DualAuthGuard } from '@app/_auth/guards/dual-auth-guard';
import { DebugPageComponent } from './pages/debug.page/debug.page.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageLayoutComponent,
    children: [
      { path: '', component: ReviewPageComponent },
      {
        path: 'debug',
        component: DebugPageComponent,
        canActivate: [DualAuthGuard],
      },
      {
        path: NAV_URLS.REVIEW_PAGE,
        component: ReviewPageComponent,
        canActivate: [DualAuthGuard],
      },
      {
        path: `${NAV_URLS.FORM_PAGE}/:formRouteName/:categoryRouteName`,
        component: FormWrapperComponent,
        canActivate: [DualAuthGuard],
      },
      {
        path: `${NAV_URLS.FORM_PAGE}/:categoryRouteName/:skillId/:educatorId`,
        component: SkillEditFormComponent,
        canActivate: [DualAuthGuard]
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SkillsCaptureRoutingModule {}
