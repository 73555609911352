import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "@env/environment";
import * as CryptoJS from 'crypto-js';
import { Observable, catchError, map, of, retry, throwError } from 'rxjs';
import { APIResponse } from '../models/api/api-response';
import { AuthenticationService } from '../../_auth/services/authentication.service';
import { SessionUserHashManagerService } from '@app/_auth/services/session-user-hash-manager.service';
import { Theme } from '../models/enums/theme';
import { SegIdentityService } from '@app/_auth/seg-identity/seg-identity.service';
import { FeaturesService } from './features.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBaseService {

  constructor(private httpClient: HttpClient,
              protected authService: AuthenticationService,
              protected sessionHasMgr: SessionUserHashManagerService,
              protected segIdentityService: SegIdentityService,
              protected modalService: ModalService
  ) { }

  public getData<T>(endpoint: string){

    return this.httpClient.get<APIResponse<T>>(endpoint, this.prepareOptions(null))
    .pipe(
      retry(3),
      map((response: any) => {
        return this.processResponse<APIResponse<T>>(response).data;
      }),
      catchError(this.handleError.bind(this)) // Bind the context
    );
  }

  public deleteData<T>(endpoint: string){
    return this.httpClient.delete<APIResponse<T>>(endpoint, this.prepareOptions(null))
    .pipe(
      retry(3),
      map((response: any) => {
        return this.processResponse<APIResponse<T>>(response).data;
      }),
      catchError(this.handleError.bind(this)) // Bind the context
    );
  }

  public postData<T>(endpoint: string, body: any){

    return this.httpClient.post(endpoint, body, this.prepareOptions(body))
    .pipe(
      retry(3),
      map((response: any) => {
        return this.processResponse<APIResponse<T>>(response).data;
      }),
      catchError(this.handleError.bind(this)) // Bind the context
    );
  }

  public postAnonymousData<T>(endpoint: string, body: any){

    return this.httpClient.post(endpoint, body, this.prepareOptions(body))
    .pipe(
      retry(3),
      map((response: any) => {
        return this.processResponse<APIResponse<T>>(response).data;
      }),
      catchError(this.handleError.bind(this)) // Bind the context
    );
  }

  private prepareOptions(payload: any): any {
    var authHeaders = this.getAuthRequestHeaders(payload);
    var httpHeaders = new HttpHeaders();
    const headerKey = 0;
    const headerValue = 1;

    authHeaders.forEach(h => {
      httpHeaders = httpHeaders.set(h[headerKey], h[headerValue]);
    })

    return { headers: httpHeaders, responseType: 'json', observe: 'response' };
  }

  private getAuthRequestHeaders(payload: any) {
    let randomSalt = Math.floor(1000000000 + Math.random() * 9000000000).toString();
    let formattedPayload = payload == null ? "{}" : (typeof payload == "string") ? payload.toLowerCase() : JSON.stringify(payload).toLowerCase();
    let secret = environment.hashingSecret + "-" + randomSalt.substring(0, 3) + "-" + formattedPayload;

    let currentUserToken = this.segIdentityService.userInfo.token;
    let headerArray: [string, string][] = [];

    if (currentUserToken) {
      headerArray.push(['Content-Type', 'application/json']);
      headerArray.push(['Authorization', 'Bearer ' + currentUserToken]);
      headerArray.push(['RequestId', randomSalt]);
      headerArray.push(['RequestMatch', CryptoJS.SHA256(secret).toString(CryptoJS.enc.Hex)]);
      headerArray.push(['AuthType', this.getAuthType()])
    }

    return headerArray;
  }

  private getAuthType(): string {
    return FeaturesService.isConsultantUrl() ? 'msal' : 'seg-identity';
  }

  public prepareAnonymousOptions(payload: any): any {
    let randomSalt = Math.floor(1000000000 + Math.random() * 9000000000).toString();
    let formattedPayload = payload == null ? "{}" : (typeof payload == "string") ? payload.toLowerCase() : JSON.stringify(payload).toLowerCase();
    let secret = environment.hashingSecret + "-" + randomSalt.substring(0, 3) + "-" + formattedPayload;

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders
      .set('Content-Type', 'application/json')
      .set('RequestId', randomSalt)
      // .set('Authorization', 'Bearer ' + environment.apiKey)
      .set('RequestMatch', CryptoJS.SHA256(secret).toString(CryptoJS.enc.Hex));

    return { headers: httpHeaders, responseType: 'json', observe: 'response' };
  }

  public prepapeSsoAnonymousOptions(payload: any, token: string): any {
    let randomSalt = Math.floor(1000000000 + Math.random() * 9000000000).toString();
    let formattedPayload = payload == null ? "{}" : (typeof payload == "string") ? payload.toLowerCase() : JSON.stringify(payload).toLowerCase();
    let secret = environment.authHashingSecret + "-" + randomSalt.substring(0, 3) + "-" + formattedPayload;

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders
    .set('Content-Type', 'application/json')
    .set('X-Company-Type', this.themeDecipher(environment.localhostThemeOverride))
    .set('Authorization', `Bearer ${token}`)
    .set('RequestMatch', CryptoJS.SHA256(secret).toString(CryptoJS.enc.Hex));

    return { headers: httpHeaders, responseType: 'json', observe: 'response' };
  }

  private themeDecipher(theme: Theme): string {
    switch (theme) {
      case Theme.ProtocolEducation:
        return 'PE';
      case Theme.TeachingPersonnel:
        return 'TP';
      case Theme.Fleet:
        return 'FT';
      default:
        return 'PE';
    }
  }

  private processResponse<T>(response: HttpResponse<T>) {
    
    if (response.status === 299) {
      throw {
        message: response.body,
        status: response.status,
        isValidationError: true
      };
    } else if (response.status !== 200){
      throw {
        message: response.body,
        status: response.status,
        isValidationError: false
      };
    } else {
      return response.body;
    }
  }

  private handleError(error: HttpErrorResponse) {
    
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
      this.modalService.showErrorDialog('A connection error has occurred', 'Please check if your online and try again. If the problem persists please contact support.');
    } else {

      if(error.status === 404){
        this.modalService.showWarningDialog('Educator data not found.', 'Please try again. If the problem persists please contact support.');
      }
      else if(error.status){
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(`Backend returned code ${error.status}, body was: `, error.error);
        this.modalService.showErrorDialog('Sorry. An error has occurred', 'Please try again. If the problem persists please contact support.');
      } 
    }

    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  public simulateError(): Observable<Response> {
    const error = new HttpErrorResponse({ status: 422 });
    return of(error) as any;
  }
}
