import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'person'
})
export class PersonPipe implements PipeTransform {

  transform(value: string, hasData: boolean, isEducator: boolean, name: string, skillCategory: string): string {

    let message = value;

    if (isEducator) {
      if (hasData) {
        message = message.split('I').join(name);
        message = message.split('have').join('has');
      } else {
        message = name + ' has not added any ' + skillCategory;
      }
    }

    return message;
  }

}
