import { Component, Input, Output } from '@angular/core';
import { Skill } from '@app/shared/models/api/skill-model';
import { Subject } from 'rxjs';
import { CategoryOption } from 'src/app/shared/models/api/category-options-model';

@Component({
  selector: 'app-field-switcher',
  templateUrl: './field-switcher.component.html',
  styleUrls: ['./field-switcher.component.scss']
})
export class FieldSwitcherComponent {

  @Input('type') type: string;
  @Input('options') options: CategoryOption[];
  @Input('skill') skill: Skill;

  @Output() valueChanged$ = new Subject<number>();

  public onValueChange($eventValue: number){
    this.valueChanged$.next($eventValue);
  }
  
}
