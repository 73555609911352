import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SegIdentityService } from '@app/_auth/seg-identity/seg-identity.service';
import { SegIdentitySsoService } from '@app/_auth/seg-identity/seg-sso.service';
import { AuthenticationService } from '@app/_auth/services/authentication.service';
import { SsoUserService } from '@app/_auth/services/sso-user-service';
import { UserService } from '@app/_auth/services/user.service';
import { Theme } from '@app/shared/models/enums/theme';
import { FeaturesService } from '@app/shared/services/features.service';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { environment } from '@env/environment';
import { User } from 'oidc-client-ts';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private _loading: boolean = false;
  public get loading(): boolean {
    return this._loading;
  }
  public set loading(value: boolean) {
    this._loading = value;

    if (this._loading) {
      this.spinnerService.showSpinner();
    }
    else {
      this.spinnerService.hideSpinner(true);
    }
  }

  public Theme = Theme;
  private unsubscribe: Subject<void> = new Subject<void>();
  public doEducatorLoginFlow: boolean = false;

  returnUrl: string;
  returnUrlParams: string;

  public appName: string;
  public logo: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private ssoAuth: SegIdentitySsoService,
    private segIdentityService: SegIdentityService,
    private userService: UserService,
    private ssoUserService: SsoUserService,
    public themeService: ThemeService,
    private spinnerService: SpinnerService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: any
  ) {
    const routeFragment: Observable<string | null> = activatedRoute.fragment;
    routeFragment.subscribe(fragment => {
      if (fragment != null) {
        let token = fragment.match(/^(.*?)&/);
        if (token) {
          token[1].replace('access_token=', '');
        }
      }
    });

    switch (this.themeService.currentTheme) {
      case Theme.Fleet:
        this.logo = 'fes_icon.png';
        this.appName = 'myFleet Skills';
        break;
      case Theme.TeachingPersonnel:
        this.logo = 'tp-logo.png';
        this.appName = 'myTP Skills';
        break;
      case Theme.ProtocolEducation:
        this.logo = 'pe_logo.svg';
        this.appName = 'myPE Skills';
        break;
    }

    if (FeaturesService.isLiteUrl()) {
      this.renderer.addClass(this.document.documentElement, 'lite');
      this.renderer.addClass(this.document.body, 'lite');
    }
  }


  async ngOnInit() {

    this.doEducatorLoginFlow = !FeaturesService.isConsultantUrl();

    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl') || 'review';

    let returnUrlCheck = sessionStorage.getItem("login-redirect");
    if (returnUrlCheck) {
      this.returnUrl = returnUrlCheck;
    }

    if (!returnUrlCheck || returnUrlCheck == '/') {

      sessionStorage.setItem("login-redirect", this.returnUrl);
    }

    if (this.segIdentityService.userInfo.authenticated) {
      this.router.navigate([this.returnUrl]);
    }
    else {

      if (this.doEducatorLoginFlow) {

        this.setupSsoLogin();
      }
      else {
        await this.authService.login('msal', this.returnUrl);
      }
    }
  }

  public setupSsoLogin() {

    this.ssoAuth.signInCallback().then((user: User) => {
      this.loading = true;
      if (user && user.access_token) {

        let companyId = environment.localhostThemeOverride;
        sessionStorage.setItem("entityId", user.profile['entityId'] as string);
        sessionStorage.setItem("candidateName", user.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'] + ' ' + user.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'])
        this.segIdentityService.userInfo.authenticated = true;

        //1
        this.userService.fetchSegIdentityUserTokenFromAuthApi(user.access_token, companyId).subscribe({
          next: (response) => {
            this.ssoUserService.setUser(response);
            this.segIdentityService.setUserInfo(response, user.access_token);

            //2
            this.authService.getTokensFromAuthApi(user.access_token).subscribe({
              next: (authResponse) => {

                let returnUrlHolder = sessionStorage.getItem("login-redirect");
                sessionStorage.removeItem("login-redirect");
                this.segIdentityService.setInfoFromToken(authResponse);
                this.loading = false;
                setTimeout(() => {
                  this.router.navigate([returnUrlHolder]);
                }, 100)
              },
              error: (err) => {
              }
            });

          },
          error(err) {
          },
        });

      }
    }).catch((err) => {
    });
  }

  public doSegIdentityLogin() {
    this.ssoAuth.login().then(() => {

    })
  }

}
