import { Theme } from "@app/shared/models/enums/theme";

export const environment = {
    apiUrl: 'https://seg-oneos-educatorskills-api-test.azurewebsites.net/api',
    localhostThemeOverride: Theme.TeachingPersonnel,
    localhostIsEducatorOverride: false,
    defaultFormRoute:  {
      TP: 'TP',
      PE: 'PE'
    },
    localhostIsLitePageOverride: false,
    educatorDomain: 'myskills',
    consultantDomain: 'educator-skills',
    liteDomain: 'lite',
    hashingSecret: '200a63sd-0cb2-47e8-bff2-de48da8d20n6',
    authHashingSecret: "309a63dd-0cb2-47d8-bff2-de48da8d20d6",
    spinDownDuration: 500,
    refreshCode: "Hamster! Frog Blue 19234!",
    ADDirectoryID: '16d89b88-ca01-4562-9f10-b9541f3eb4f7',
    ApplicationID: "43e84404-b87d-462f-ae09-d4b5b79bdae6",

    msalConfig: {
      authApi: "https://seg-os-central-azure-identity-api-test.azurewebsites.net/v1/",
      apiResourceAndScope: [
        'api://b7f9e073-818a-452d-91b3-d80e9233fa3e/', ['access_as_user']
      ] as [string, string[]],
      apiScopes: [
        'api://b7f9e073-818a-452d-91b3-d80e9233fa3e/access_as_user'
      ]
    },
    //SSO Seg Identity
    segIdentityAuthApi: 'https://seg-os-central-segidentity-auth-api-test.azurewebsites.net/v1/',
    stsAuthorityTP: "https://identity-test.teachingpersonnel.com/",
    stsAuthorityPE: "https://identity-test.protocol-education.com",
    stsAuthorityFES: "https://identity-test.fleeteducationservices.com/",
    stsClientIdTP: "tp-test-educator-skillscapture",
    stsClientIdPE: "pe-test-educator-skillscapture",
    // Debug
    domOutlining: false,
    debugMode: false
};
