import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SegIdentityService } from '@app/_auth/seg-identity/seg-identity.service';
import { AuthenticationService } from '@app/_auth/services/authentication.service';
import { SessionUserHashManagerService } from '@app/_auth/services/session-user-hash-manager.service';
import { SsoUserService } from '@app/_auth/services/sso-user-service';
import { EducatorDetails } from '@app/shared/models/api/educator-model';
import { SegUser } from '@app/shared/models/auth/seg-user-model';
import { ApiBaseService } from '@app/shared/services/api-base.service';
import { FeaturesService } from '@app/shared/services/features.service';
import { ModalService } from '@app/shared/services/modal.service';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EducatorService extends ApiBaseService {
  public educatorDetails$: BehaviorSubject<EducatorDetails> = new BehaviorSubject<EducatorDetails>(null);
  private forcedEducatorId: number;
  sub: any;

  constructor(
    httpClient: HttpClient,
    authService: AuthenticationService,
    sessionMgrHash: SessionUserHashManagerService,
    segIdentityService: SegIdentityService,
    ssoUserService: SsoUserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    modalService: ModalService
  ) {
    super(httpClient, authService, sessionMgrHash, segIdentityService, modalService);

    if(FeaturesService.VIEW_ALL_EDUCATORS()){
      let educatorId = Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get('educatorId')) || -1;

      if(educatorId < 0){
        educatorId = Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get('educatorid')) || -1;
      }
      
      if(educatorId > -1){
        this.loadEducatorProfile(educatorId);
      }
      else{
        this.router.navigate(["/404"]);
      }

    }else{
      this.getEducatorProfileByCurrentUserId(ssoUserService);
    }
  }

  private getEducatorProfileByCurrentUserId(ssoUserService: SsoUserService) {
    if (ssoUserService.currentUser$.value) {
      this.forcedEducatorId = ssoUserService.currentUser$.value.id;
      this.loadEducatorProfile(this.forcedEducatorId);
    }
    else {
      ssoUserService.currentUser$.subscribe({
        next: (nextValue: SegUser) => {
          if (nextValue) {
            this.forcedEducatorId = ssoUserService.currentUser$.value.id;
            this.loadEducatorProfile(this.forcedEducatorId);
          }
        }
      });
    }
  }

  private loadEducatorProfile(educatorId: number): void {

    let formEndpoint = `${environment.apiUrl}/educator/${educatorId}`;

    this.getData<EducatorDetails>(formEndpoint).subscribe({
      next: (response) => {
        this.educatorDetails$.next(response);
      },
      error: (err) => {
        
      },
    });
  }
}
