import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSkillsValue } from '@app/shared/models/api/user-skills-values';
import { environment } from "@env/environment";
import { AuthenticationService } from '@app/_auth/services/authentication.service';
import { Category } from '@app/shared/models/api/category-model';
import { FormCategories } from '@app/shared/models/api/form-categories-model';
import { ApiBaseService } from '@app/shared/services/api-base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionUserHashManagerService } from '@app/_auth/services/session-user-hash-manager.service';
import { SegIdentityService } from '@app/_auth/seg-identity/seg-identity.service';
import { ModalService } from '@app/shared/services/modal.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { Theme } from '@app/shared/models/enums/theme';

@Injectable({
  providedIn: 'root'
})
export class SkillsService extends ApiBaseService {
  
  public formCategories$: BehaviorSubject<FormCategories> = new BehaviorSubject<FormCategories>(null);
  private formRoute: string = '';

  constructor(
    httpClient: HttpClient, 
    authService: AuthenticationService, 
    sessionMgrHash: SessionUserHashManagerService, 
    segIdentityService: SegIdentityService,
    modalService: ModalService,
    themeService: ThemeService) {
    
    super(httpClient, authService, sessionMgrHash, segIdentityService, modalService);

    switch(themeService.currentTheme){
      case Theme.TeachingPersonnel:
        this.formRoute = environment.defaultFormRoute.TP;
        break;
      
      case Theme.ProtocolEducation:
        this.formRoute = environment.defaultFormRoute.PE;
        break;
    }

    this.loadFormCategories();
  }

  public deleteUserForm(educatorId: number) : Observable<boolean> {
    
    let deleteUserFormEndpoint = `${environment.apiUrl}/form/${this.formRoute}/${educatorId}`;

    return this.deleteData<boolean>(deleteUserFormEndpoint);
  }

  public loadFormCategories(): void {
    
    let formEndpoint = `${environment.apiUrl}/form/${this.formRoute}`;

    this.getData<FormCategories>(formEndpoint).subscribe({
      next: (response) => {
        this.formCategories$.next(response);
      },
      error: (err)  => {
        
      },
    });
  }

  public getformCategory(formRoute: string, categoryRoute: string, educatorId: number) : Observable<Category>{
    let formCategoryEndpoint = `${environment.apiUrl}/form/${formRoute}/${categoryRoute}/${educatorId}`;

    return this.getData<Category>(formCategoryEndpoint);
  }

  public saveUserSkillValue(userSkillValue: UserSkillsValue) : Observable<UserSkillsValue> {
    let saveSkillsValueEndpoint = `${environment.apiUrl}/form/userskillvalue`;

    return this.postData<any>(saveSkillsValueEndpoint, userSkillValue);
  }

  public deleteUserSkillValue(userSkillValue: UserSkillsValue) : Observable<UserSkillsValue> {
    let deleteUserFormEndpoint = `${environment.apiUrl}/form/${this.formRoute}/${userSkillValue.userId}/${userSkillValue.skillId}`;

    return this.deleteData<any>(deleteUserFormEndpoint);
  }

  public getPreviousCategory(routeName: string): Category {
    let currentCategory: Category = this.formCategories$.getValue().categories.find((cat: Category) => cat.categoryRoute === routeName);
    let displayOrder: number = currentCategory.displayOrder;
    let previousCategory: Category = this.formCategories$.getValue().categories.find((cat2: Category) => cat2.displayOrder === displayOrder-1);

    return previousCategory;
  }

  public getNextCategory(routeName: string): Category {
    let currentCategory: Category = this.formCategories$.getValue().categories.find((cat: Category) => cat.categoryRoute === routeName);
    let displayOrder: number = currentCategory.displayOrder;
    let nextCategory: Category = this.formCategories$.getValue().categories.find((cat2: Category) => cat2.displayOrder === displayOrder+1);

    return nextCategory;
  }


}
