import { Pipe, PipeTransform } from '@angular/core';
import { FeaturesService } from '../services/features.service';
import { FeaturesEnum } from '../models/enums/features';

@Pipe({
  name: 'FEATURES'
})
export class FeaturesPipe implements PipeTransform {


  transform(value: FeaturesEnum): boolean {

    switch(value){
        case FeaturesEnum.SKILL_NAV:
            return FeaturesService.SKILL_NAV();
        case FeaturesEnum.SKILL_ITEM_EDIT:
            return FeaturesService.SKILL_ITEM_EDIT();
        case FeaturesEnum.CONSULTANT_VIEW:
            return FeaturesService.CONSULTANT_VIEW();
        case FeaturesEnum.VIEW_ALL_EDUCATORS:
            return FeaturesService.VIEW_ALL_EDUCATORS();
        default: 
            return false;
    }
  }

}