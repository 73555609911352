import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SessionUserHashManagerService } from './session-user-hash-manager.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { environment } from '@env/environment';
import { SegUser } from '@app/shared/models/auth/seg-user-model';
import { UserSessionData } from '@app/shared/models/auth/user-session-model';

@Injectable({ providedIn: 'root' })
export class SsoUserService {

  public userSessionData: UserSessionData = new UserSessionData();
  public currentUser$: BehaviorSubject<SegUser> = new BehaviorSubject(null);
  private _userName: string | null = '';

  constructor(
    private http: HttpClient,
    protected hashManagerService: SessionUserHashManagerService,
    private themeService: ThemeService
  ) {
    this.setUserSessionData();
  }

  get userName() {
    if (!this._userName || this._userName == '') {
      this._userName = sessionStorage.getItem("candidateName")
    }
    return this._userName;
  }
  /*
   * OTHER MEMBERS 
   */
  public setUser(user: SegUser) {
    // let user = response.json();
    if (user && user.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      localStorage.setItem('originalUser', JSON.stringify(user));
      this.hashManagerService.setUserHash(user)
      this.setUserSessionData();
      this.currentUser$?.next(user);
    }
  }

  public get currentUserValue(): SegUser {
    return this.currentUser$.value;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    sessionStorage.clear();
    this.setUserSessionData();
  }

  setUserSessionData() {
    var currUser = localStorage.getItem('currentUser');
    var currentUser;
    if (currUser !== 'undefined' && currUser !== 'null' && (currUser != null || currUser != undefined)) {
      currentUser = JSON.parse(currUser);
      this.currentUser$.next(currentUser);
    }
    else {
      currentUser = null;
    }

    this.userSessionData.currentUser = currentUser;

  }
}