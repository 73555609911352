import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SkillsService } from '../../services/skills.service';
import { NAV_URLS } from 'src/app/shared/constants/url-constants';
import { Subscription, filter } from 'rxjs';
import { FormCategories } from 'src/app/shared/models/api/form-categories-model';
import { Category } from 'src/app/shared/models/api/category-model';
import { UserSkillsValue } from '@app/shared/models/api/user-skills-values';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { EducatorService } from '../../services/educator.service';
import { EducatorDetails } from '@app/shared/models/api/educator-model';
import { FeaturesEnum } from '@app/shared/models/enums/features';
import { UtilityBarService } from '@app/shared/services/utility-bar.service';

@Component({
  selector: 'app-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss']
})
export class FormWrapperComponent implements OnInit, OnDestroy {

  public data: FormCategories;
  public validCategories: string[] = [];

  public formRouteName: string;
  public categoryRouteName: string;
  public category: Category;

  public previousCategory: Category;  
  public previousLinkText: string;
  public previousRouterLink: string;
  
  public nextCategory: Category;
  public nextLinkText: string;
  public nextRouterLink: string;
  private routeSub: Subscription;
  public formLoading: boolean = false;
  public currentEducator: EducatorDetails;
  public Features = FeaturesEnum;

  @ViewChild('educatorProfile', { static: true }) educatorProfile: TemplateRef<any>;
  

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private skillsService: SkillsService,
    private spinnerService: SpinnerService,
    private educatorService: EducatorService,
    private utilityBarService: UtilityBarService) {

  }

  ngOnInit() {

    console.log('FORM WRAPPER NGONINIT');

    if(this.skillsService.formCategories$.value){
      this.processCategoriesUpdate(this.skillsService.formCategories$.value, this.categoryRouteName);
    }

    this.skillsService.formCategories$.subscribe({
      next: (nextValue: FormCategories) => {
        
        if(nextValue){
          this.loadUp();
        }
      }      
    });

    this.routeSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.loadUp();
    });
  }

  ngAfterViewInit(){
    this.utilityBarService.addTemplate(this.educatorProfile);
  }

  ngOnDestroy(): void {
    if(this.routeSub){
      this.routeSub.unsubscribe();
    }

    this.utilityBarService.clearTemplates();
  }
  
  
  private loadUp() {

    this.formLoading = true;
    this.spinnerService.showSpinner();

    let formRouteName = this.activatedRoute.snapshot.params['formRouteName'];
    let categoryRouteName = this.activatedRoute.snapshot.params['categoryRouteName'];

    this.formRouteName = formRouteName;
    this.categoryRouteName = categoryRouteName;

    this.educatorService.educatorDetails$.subscribe({
      next: (nextValue: EducatorDetails) => {
        if(nextValue){
          this.currentEducator = nextValue;
          this.getCategoryData(categoryRouteName);
        }
      }});

    if(this.educatorService.educatorDetails$.value){
      this.currentEducator = this.educatorService.educatorDetails$.value;
      this.getCategoryData(categoryRouteName);
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  private getCategoryData(categoryRouteName: any) {
    var formCategories = this.skillsService.formCategories$.value;
    if (formCategories) {
      this.processCategoriesUpdate(formCategories, categoryRouteName);

      this.skillsService.getformCategory(this.formRouteName, categoryRouteName, this.currentEducator.id).subscribe({
        next: (x) => {
          console.log('RESP: ', x);
          this.category = x;

        },
        error(err) {
        },
        complete: () => {
          this.formLoading = false;
          this.spinnerService.hideSpinner();
        }
      });

    }
  }

  private processCategoriesUpdate(updatedCategories: FormCategories, categoryRouteName: string) {
    if (updatedCategories && categoryRouteName) {
      this.data = updatedCategories;

      // Validate Category exists
      this.data.categories.forEach((element: Category) => {
        this.validCategories.push(element.categoryRoute);
      });

      if (!this.validCategories.includes(categoryRouteName)) {
        console.warn(categoryRouteName + ' doesnt exist!');
        this.router.navigate([`./${NAV_URLS.REVIEW_PAGE}`]);
      }

      this.category = this.data.categories.find((category: Category) => category.categoryRoute === this.categoryRouteName);

      this.previousCategory = this.skillsService.getPreviousCategory(this.categoryRouteName);
      this.getPreviousLinkDetails();

      this.nextCategory = this.skillsService.getNextCategory(this.categoryRouteName);
      this.getNextLinkDetails();


    }
  }


  public getPreviousLinkDetails() {

    if (this.previousCategory == null) {
      this.previousLinkText = 'Home';
      this.previousRouterLink = `/${NAV_URLS.REVIEW_PAGE}`;
    }
    else {
      this.previousLinkText = this.previousCategory.name;
      this.previousRouterLink = `../${this.previousCategory.categoryRoute}`;
    }
  }
  
  
  public getNextLinkDetails() {

    if (this.nextCategory == null) {
      this.nextLinkText = 'Review';
      this.nextRouterLink = `/${NAV_URLS.REVIEW_PAGE}`;
    }
    else {
      this.nextLinkText = this.nextCategory.name;
      this.nextRouterLink = `../${this.nextCategory.categoryRoute}`;
    }
  }

  public onValueChange($event: UserSkillsValue) {
    if($event){

      $event.userId = this.currentEducator.id;
      $event.formId = this.data.id;


      if($event.isSelected){

        // SAVE SKILL
        this.spinnerService.showSaving();
        this.skillsService.saveUserSkillValue($event).subscribe({
          next: (x) => {
          },
          error(err) {
          },
          complete: () => {
            this.spinnerService.hideSaving();
          }
        });
      }
      else{
        // DELETE SKILL

        this.spinnerService.showSaving();
        this.skillsService.deleteUserSkillValue($event).subscribe({
          next: (x) => {
          },
          error(err) {
          },
          complete: () => {
            this.spinnerService.hideSaving();
          }
        });
      }

      
    }
    
  }
}
