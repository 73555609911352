import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Theme } from '../models/enums/theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private activeTheme = new BehaviorSubject(Theme.NoTheme);
  public currentTheme = Theme.NoTheme;

  constructor(
    private httpClient: HttpClient
  ) {
    const parsedUrl = new URL(window.location.href);
    const urlHost = parsedUrl.host;

    if (urlHost.indexOf("teachingpersonnel") > -1) {
      this.setActiveTheme(Theme.TeachingPersonnel);
    } else if (urlHost.indexOf("protocol-education") > -1) {
      this.setActiveTheme(Theme.ProtocolEducation);
    } else if (urlHost.indexOf("fleeteducationservices") > -1) {
      this.setActiveTheme(Theme.Fleet);
    } else {
      this.setActiveTheme(environment.localhostThemeOverride);
    }
  }

  public getActiveTheme() {
    return this.activeTheme.asObservable();
  }

  public setActiveTheme(name: Theme) {
    this.currentTheme = name;
    this.activeTheme.next(name);
    var body = document.querySelector("body");
    var favIcon = document.querySelector("link[rel*='icon']");
    var title = document.querySelector("title");
    
    // Clear any existing classes. 
    body.setAttribute('class', '');

    switch (name) {
      case Theme.TeachingPersonnel:

        if (body) {
          body.classList.add("theme-tp");
        }

        if (favIcon) {
          favIcon.setAttribute("href", "assets/favicons/tp-favicon.png");
        }
        if (title) {
          title.innerHTML = "Skills Capture | Teaching Personnel";
        }
        break;
      case Theme.ProtocolEducation:
        if (body) {
          body.classList.add("theme-pe");
        }
        if (favIcon) {
          favIcon.setAttribute("href", "assets/favicons/pe-favicon.png");
        }
        if (title) {
          title.innerHTML = "Skills Capture | Protocol Education";
        }
        break;
      case Theme.Fleet:
        if (body) {
          body.classList.add("theme-fleet");
        }
        if (favIcon) {
          favIcon.setAttribute("href", "assets/favicons/ft-favicon.png");
        }
        if (title) {
          title.innerHTML = "Skills Capture | Fleet Tutors";
        }
        break;
    }
  }
}
