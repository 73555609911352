import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticatedUser } from '@app/shared/models/api/authenticated-user-model';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { environment } from '@env/environment';
import { Subject, filter } from 'rxjs';

@Injectable()
export class MsalAuthenticationService implements OnDestroy {

  private readonly _destroying$ = new Subject<void>();
  public authenticatedUserUpdated: Subject<AuthenticatedUser> = new Subject<AuthenticatedUser>();
    subscription: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    public activedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public async initializeMsalService(redirectOnLogin: boolean): Promise<void> {
    if (redirectOnLogin) {
      this.authService.handleRedirectObservable().subscribe({
        next: (result: AuthenticationResult) => {
          // Perform actions related to user accounts here
        },
        error: (error) => console.log(error)
      })
    }

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED || msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          const payload = result.payload as AuthenticationResult;
          this.authService.instance.setActiveAccount(payload.account);
          if (redirectOnLogin) {
            window.location.pathname = "/";
          } else {
            // this.loginPopup(false);
            this.loginRedirect(redirectOnLogin);
          }
        }
      });

    this.subscription = this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
    ).subscribe((result: EventMessage) => {
      this.subscription.unsubscribe();
      this.authService.logout();
    });
  }

  private async checkAndSetActiveAccount(redirectOnLogin: boolean, redirectPath: string = "") {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */

    let activeAccount = this.authService.instance.getActiveAccount();

    if (activeAccount || this.authService.instance.getAllAccounts().length > 0) {

      let account: AccountInfo;
      if (activeAccount) {
        account = activeAccount;
      } else {
        let accounts = this.authService.instance.getAllAccounts();
        this.authService.instance.setActiveAccount(accounts[0]);
        account = accounts[0];
      }
      const accessTokenRequest = {
        scopes: environment.msalConfig.apiScopes,
        account: account,
      };

      try {
        let accessTokenResponse = await this.authService.instance.acquireTokenSilent(accessTokenRequest);
        this.triggerAuthenticatedUserUpdate(accessTokenResponse.accessToken, account.username, redirectOnLogin, redirectPath);
      }
      catch (error: any) {
        console.error(`MSAL error ${error.errorCode}: ${error.errorMessage}`);
        this.router.navigate(['/login']);
      }
      
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.instance.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.instance.loginRedirect();
      }
    }
  }

  public loginRedirect(redirectOnLogin: boolean, redirectPath: string = "") {
    try {
      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      ).subscribe(() => {
        this.checkAndSetActiveAccount(redirectOnLogin, redirectPath);
      });
    } catch (error) {
      this.logout(true);
    }
  }

  public loginPopup(redirectOnLogin: boolean = true) {
    if (this.msalGuardConfig.authRequest) {
      this.authService.instance.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .then((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          this.triggerAuthenticatedUserUpdate(response.accessToken, response.account.username, redirectOnLogin);
        });
    } else {
      this.authService.instance.loginPopup()
        .then((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          this.triggerAuthenticatedUserUpdate(response.accessToken, response.account.username, redirectOnLogin);
        });
    }
  }

  public logout(popup?: boolean) {
    if (popup) {
      this.authService.instance.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.instance.logoutRedirect();
    }
  }

  private triggerAuthenticatedUserUpdate(accessToken: string, username: string, redirectOnLogin: boolean, redirectPath: string = ""): void {
    let authenticatedUser = new AuthenticatedUser();
    if (!accessToken && accessToken != "") {
      authenticatedUser.authenticated = true;
      authenticatedUser.userEmail = username;
      authenticatedUser.msalToken = accessToken;
    }
    this.authenticatedUserUpdated.next(authenticatedUser);
    if (redirectOnLogin) {
      let path = redirectPath == "" ? "/" : redirectPath;
      window.location.replace(path);
    }
  }

}
