import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SegIdentityService } from '@app/_auth/seg-identity/seg-identity.service';
import { SegIdentitySsoService } from '@app/_auth/seg-identity/seg-sso.service';
import { AuthenticationService } from '@app/_auth/services/authentication.service';
import { SsoUserService } from '@app/_auth/services/sso-user-service';
import { UserService } from '@app/_auth/services/user.service';
import { Theme } from '@app/shared/models/enums/theme';
import { FeaturesService } from '@app/shared/services/features.service';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login-unauthorised.component.html',
  styleUrls: ['./login-unauthorised.component.scss']
})
export class LoginUnauthorisedComponent implements OnInit {

  public Theme = Theme;
  public doEducatorLoginFlow: boolean = false;

  returnUrl: string;
  returnUrlParams: string;

  public appName: string;
  public logo: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private ssoAuth: SegIdentitySsoService,
    private segIdentityService: SegIdentityService,
    private userService: UserService,
    private ssoUserService: SsoUserService,
    public themeService: ThemeService,
    private spinnerService: SpinnerService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: any
  ) {
    const routeFragment: Observable<string | null> = activatedRoute.fragment;
    routeFragment.subscribe(fragment => {
      if (fragment != null) {
        let token = fragment.match(/^(.*?)&/);
        if (token) {
          token[1].replace('access_token=', '');
        }
      }
    });

    switch (this.themeService.currentTheme) {
      case Theme.Fleet:
        this.logo = 'fes_icon.png';
        this.appName = 'myFleet Skills';
        break;
      case Theme.TeachingPersonnel:
        this.logo = 'tp-logo.png';
        this.appName = 'myTP Skills';
        break;
      case Theme.ProtocolEducation:
        this.logo = 'pe_logo.svg';
        this.appName = 'myPE Skills';
        break;
    }

    if(FeaturesService.isLiteUrl()) {
      this.renderer.setStyle(this.document.documentElement , 'height', 'auto');
      this.renderer.setStyle(this.document.body, 'height', 'auto');
    }
  }


  async ngOnInit() {
    this.doEducatorLoginFlow = !FeaturesService.isConsultantUrl();
  }

  public doLogout(){
    if(this.doEducatorLoginFlow){
      this.ssoAuth.logout();
    }
    else{
      this.authService.logout();
    }
  }

}
