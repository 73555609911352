import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SkillsService } from '../../services/skills.service';
import { Category } from '@app/shared/models/api/category-model';
import { Skill } from '@app/shared/models/api/skill-model';
import { FormCategories } from '@app/shared/models/api/form-categories-model';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { environment } from '@env/environment';
import { DropDownOption } from '@app/shared/models/forms/dropdown-option';
import { ModalService } from '@app/shared/services/modal.service';
import { UserSkillsValue } from '@app/shared/models/api/user-skills-values';
import { EducatorService } from '../../services/educator.service';
import { FeaturesService } from '@app/shared/services/features.service';
import { SsoUserService } from '@app/_auth/services/sso-user-service';

@Component({
  selector: 'app-skill-edit-form',
  templateUrl: './skill-edit-form.component.html',
  styleUrls: ['./skill-edit-form.component.scss']
})
export class SkillEditFormComponent implements OnInit, OnDestroy {

  private routeSub: Subscription;
  public currentEducatorId: number;
  public category: Category;
  public skill: Skill;
  public dropDownReasonTypes: DropDownOption<string>[];
  public showTitle: boolean = true;
  public selectedIndex: number = -1;
  public showValidationError = false;

  @Input("category") InputCategory: any;
  @Input("skillId") InputSkillId: any;
  @Input("formId") InputFormId: any;

  private dropdownOptions: string[] = ['Client skill level complaint', 'Conversation with educator', 'Gaming the system']

  @Output() onDataChanged = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private skillsService: SkillsService,
    private spinnerService: SpinnerService,
    private educatorService: EducatorService,
    public modalService: ModalService,
    private ssoUserService: SsoUserService) {

    this.dropDownReasonTypes = this.dropdownOptions.map((x) => ({ value: x, isSelected: false, data: x } as DropDownOption<string>));
  }

  ngOnInit(): void {

    let categoryRouteNameParam = this.InputCategory;
    let skillIdParam = this.InputSkillId;

    //This value should be loaded by this point as this component loads in a dialog after other page loads
    this.currentEducatorId = this.educatorService.educatorDetails$.value.id;

    if (this.skillsService.formCategories$.value) {
      this.loadUp(this.skillsService.formCategories$.value, categoryRouteNameParam, skillIdParam);
    }

    this.skillsService.formCategories$.subscribe({
      next: (nextValue: FormCategories) => {

        if (nextValue) {
          this.loadUp(nextValue, categoryRouteNameParam, skillIdParam);
        }
      }
    });
  }


  private loadUp(formCategories: FormCategories, categoryRoute: string, skillId: number) {
    this.category = formCategories.categories.find((category: Category) => category.categoryRoute == categoryRoute);

    this.skillsService.getformCategory(formCategories.formRoute, categoryRoute, this.currentEducatorId).subscribe({
      next: (x) => {
        console.log('RESP: ', x);
        this.category = x;
        this.skill = this.category.skills.find(x => x.id == skillId);
        if (!this.skill.lockInfo) {
          this.skill.lockInfo = { reasonDescription: '', reasonType: '' };
        }
        else {
          this.selectedIndex = this.dropDownReasonTypes.findIndex(x => x.value.toLowerCase() == this.skill.lockInfo.reasonType.toLowerCase());
        }

      },
      error: (err) => {
        
      },
      complete: () => {
        this.spinnerService.hideSpinner();
      }
    });
  }

  ngOnDestroy(): void {
    console.log('SKILL EDIT NGONDESTROY');

    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  onOptionChange($event: number) {

    this.skill.selectedOption = $event
  }

  onSkillToggled($event: boolean) {

    this.skill.isSelected = $event;
  }

  selectReason($event: any) {
    this.skill.lockInfo.reasonType = $event;
    this.showValidationError = false;
  }


  public saveChanges() {
    console.log(this.skill);

    this.showValidationError = false;
    if (!this.skill.lockInfo.reasonType) {
      this.showValidationError = true;
      //SHOW VALIDATION ERROR IN UI
      return;
    }

    // SAVE SKILL
    let userSkillValue = {
      formId: this.InputFormId,
      isSelected: this.skill.isSelected,
      skillId: this.skill.id,
      optionId: this.skill.selectedOption,
      lockInfo: this.skill.lockInfo,
      userId: this.currentEducatorId
    } as UserSkillsValue;

    this.spinnerService.showSaving();
    this.skillsService.saveUserSkillValue(userSkillValue).subscribe({
      next: (x) => {

        if(!userSkillValue.isSelected){
          this.deleteUserSkillValue(userSkillValue);
        }
        
      },
      error(err) {

      },
      complete: () => {

        if(userSkillValue.isSelected){
          this.spinnerService.hideSaving();
          this.modalService.closeWindow();
          this.onDataChanged.emit(true);
        }
        
      }
    });
  }

  deleteUserSkillValue(userSkillValue: UserSkillsValue) {
    this.skillsService.deleteUserSkillValue(userSkillValue).subscribe({
      next: (x) => {

      },
      error(err) {

      },
      complete: () => {

        if(!userSkillValue.isSelected){
          this.spinnerService.hideSaving();
          this.modalService.closeWindow();
          this.onDataChanged.emit(true);
        }
        
      }
    });
  }

}
