import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { filter, Observable, switchMap } from 'rxjs';
import { environment } from '@env/environment';
import { AuthenticatedUser } from '../../shared/models/api/authenticated-user-model';
import { SegIdentityService } from '../seg-identity/seg-identity.service';

@Injectable()
export class MsalAuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private segIdentityService: SegIdentityService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, doRerouting: boolean = true): Observable<boolean> {
    
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        switchMap(async () => {

          if (this.segIdentityService.userInfo.authenticated) {
            return true;
          } else {

            let activeAccount = this.msalService.instance.getActiveAccount();

            if (activeAccount || this.msalService.instance.getAllAccounts().length > 0) {

              let account: AccountInfo;
              if (activeAccount) {
                account = activeAccount;
              } else {
                let accounts = this.msalService.instance.getAllAccounts();
                this.msalService.instance.setActiveAccount(accounts[0]);
                account = accounts[0];
              }

              const accessTokenRequest = {
                scopes: environment.msalConfig.apiScopes,
                account: account,
              };

              try {
                let accessTokenResponse =
                  await this.msalService.instance.acquireTokenSilent(
                    accessTokenRequest
                  );
  
                if (accessTokenResponse.accessToken) {
                  let authenticatedUser = new AuthenticatedUser();
                  authenticatedUser.authenticated = true;
                  authenticatedUser.userName = accessTokenResponse.account.name;
                  authenticatedUser.userEmail = account.username;
                  authenticatedUser.msalToken = accessTokenResponse.accessToken;
  
                  this.segIdentityService.userInfo = authenticatedUser;
  
                  return true;
                }
              } catch (error: any) {
                console.error(
                  `MSAL error ${error.errorCode}: ${error.errorMessage}`
                );

                if(doRerouting)
                  this.router.navigate(['/login']);
              }
            }

            if(doRerouting)
              this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

            return false;
          }
          
        })
      );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}

