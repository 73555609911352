<div class="form-wrapper">

  <nav class="step-nav step-nav--back">
    <i app-svg-icon name="arrow2-left" classList="icon  icon-20 rag-r"></i>
    <span [routerLink]="[previousRouterLink]">{{ previousLinkText }}</span>
  </nav>

  <div class="main" *ngIf="category">
    <div class="form">
      <app-category-form [currentEducator]="currentEducator" (valueChanged$)="onValueChange($event)" *ngIf="category.skills && category.skills.length > 0" [category]="category"></app-category-form>
    </div>
  </div>

  <nav class="step-nav step-nav--next">
    <span [routerLink]="[nextRouterLink]">{{ nextLinkText }}</span>
    <i app-svg-icon name="arrow2-right" classList="icon  icon-20 rag-r"></i>
  </nav>

</div>

<ng-template #educatorProfile>
  <app-profile-bar *ngIf="Features.CONSULTANT_VIEW | FEATURES"  [currentEducator]="currentEducator" [badgesize]="48"></app-profile-bar>
</ng-template>