import { Component, ViewEncapsulation } from '@angular/core';
import { UtilityBarService } from '@app/shared/services/utility-bar.service';

@Component({
  selector: 'app-utility-bar',
  templateUrl: './utility-bar.component.html',
  styleUrl: './utility-bar.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class UtilityBarComponent {

  constructor(public utilityBarService: UtilityBarService) { }

  ngOnInit(): void {
  }

}
