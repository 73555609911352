import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { SpinState } from '../models/enums/spinner-state';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private _spinState = new BehaviorSubject<string>('closed');
  spinState$ = this._spinState.asObservable();

  get spinState() {
    return this._spinState.getValue();
  }
  set spinState(state: string) {
    this._spinState.next(state);
  }
  
  // 

  private _saveState = new BehaviorSubject<boolean>(false);
  saveState$ = this._saveState.asObservable();

  get saveState() {
    return this._saveState.getValue();
  }
  set saveState(state: boolean) {
    this._saveState.next(state);
  }

  constructor() { }

  public showSpinner() {
    this.spinState = SpinState.Open;
  }

  public hideSpinner(forceClose = false) {
    this.spinState = SpinState.Closing;

    // Give time for CSS to fade-out the spinner,
    setTimeout(() => {
      this.spinState = SpinState.Closed;
    }, forceClose ? 0 : environment.spinDownDuration);
  }


  public showSaving() {
    this.saveState = true;
  }

  public hideSaving() {
    setTimeout(() => {
      this.saveState = false;
    }, environment.spinDownDuration);
  }

}
