import { Component, HostListener } from '@angular/core';
import { MenuService } from '@app/shared/services/menu.service';

@Component({
  selector: 'app-header-dropdown',
  templateUrl: './header-dropdown.component.html',
  styleUrl: './header-dropdown.component.scss'
})
export class HeaderDropdownComponent {

  menuOpen: boolean;

  constructor(
    private menuService: MenuService
  ){}

  @HostListener('window:resize', ['$event']) onResize($event: any) {
    this.menuService.close();
  }

  public ngOnInit() {
    this.menuService.openState.subscribe(value => {
      this.menuOpen = value;
    })
  }
}
